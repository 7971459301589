import { Controller } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React from "react";
import {FieldError, Label, TextField} from 'react-aria-components';
export const HubCKEditor = ({value, control, name, placeholder, rules, config}: { value:string, control:any, name:string, placeholder?:string, rules?:any, config?: any }) => {
    const defaultConfig = {
        removePlugins: ['Heading'],
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link'],
        placeholder
    };

    return (
        <Controller
            defaultValue={value}
            name={name}
            control={control}
            rules={{ required: "Description is required." }}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, invalid } }) => (
                <TextField name={name}
                           onBlur={onBlur}
                           validationBehavior="aria"
                           isInvalid={invalid}
                           isRequired={true}>
                    <Label className={'text-left text-grey-41 font-semibold flex gap-2'}>Profile</Label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        config={config??defaultConfig}
                        /*onReady={editor => {
                            // Editor is ready to use
                        }}*/
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                        }}
                        onBlur={() => onBlur()}
                        /*onFocus={(event, editor) => {
                            // Optional: Handle focus event
                        }}*/
                    />
                    <FieldError className={'text-left text-error'} >
                        {error?.message}
                    </FieldError>
                </TextField>
            )}
        />
);
};

import {useStats} from "react-instantsearch";
import React from "react";

export function ResultsCount() {
    const {
        hitsPerPage,
        nbHits,
        areHitsSorted,
        nbSortedHits,
        nbPages,
        page,
        processingTimeMS,
        query,
    } = useStats();

    /*console.log({
        hitsPerPage,
        nbHits,
        areHitsSorted,
        nbSortedHits,
        nbPages,
        page,
        processingTimeMS,
        query,
    });*/

    return (
        <h3 className={'text-body-24'}>{nbHits} <span className={'opacity-80'}>tutors</span></h3>
    );
}

import {Icon} from "../../atoms/adornments/Icon/Icon";
import React from "react";
import Img from '../../assets/images/userpic_2.svg';


export function Profile() {
    return (
        <div className={'flex flex-col bg-white rounded p-6 gap-6 rounded-8'}>
            <div className={'flex gap-6 items-center'}>
                <img src={Img} alt="" height={'150px'} width={'150px'} className={'rounded-2xl h-[150px] w-[150px] flex-0'} />
                <div className={'h-fit'}>
                    <span>TOP TUTOR</span>
                    <h2 className={'text-title-32'}>Christina H.</h2>
                    <span>
                    Licensed Professional Teacher + TEFL Certified 120hrs & 5 YEARS TEACHING ONLINE with 1000+ Lessons
                </span>
                </div>
            </div>
            <dl className={'flex flex-col gap-input'}>
                <div className={'flex gap-badge flex'}>
                    <Icon name={'star'}></Icon>
                    <dt>Rate:</dt>
                    <dd>4.9 <span>82 lessons</span></dd>
                </div>
                <div className={'flex gap-badge flex'}>
                    <Icon name={'tutors'}></Icon>
                    <dt>Teaches:</dt>
                    <dd>English</dd>
                </div>
                <div className={'flex gap-badge flex'}>
                    <Icon name={'translation'}></Icon>
                    <dt>Speaks:</dt>
                    <dd>
                        <dl className={'flex'}>
                            <div className={'flex gap-badge'}>
                                <dt>English</dt>
                                <dd>Native</dd>
                                <span>,</span>
                            </div>
                            <div className={'flex gap-badge'}>
                                <dt>Spanish</dt>
                                <dd>B1</dd>
                            </div>
                        </dl>
                    </dd>
                </div>
            </dl>
            <div className={'flex flex-col gap-4 mt-6'}>
                <h3>About me:</h3>
                <p>My first university degree was in linguistics. I understand how language works, and how
                    our mouths make sounds, in a very deep way. Since 2021 I have tutored hundreds of
                    students (on 2 platforms), which has helped me discover what adult students need and
                    develop effective methods and approaches to supporting those needs. Just for fun, in
                    early 2023 I've also completed a TEFOL/TESL certificate in teaching English as a foreign
                    language.</p>
                <p>
                    My first university degree was in linguistics. I understand how language works, and how
                    our mouths make sounds, in a very deep way. Since 2021 I have tutored hundreds of students
                </p>
                <span className={'text-primary'}>Show more</span>
            </div>
        </div>
    )
}

import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {HubButton} from "../../atoms/inputs/Button/Button";
import React from "react";
import Img from '../../assets/images/tutor-in-a-hat.svg';

export function Booking() {
    return (
        <div className={'w-[400px] flex flex-col gap-6 grow-0 bg-white pb-6 rounded-[16px]'}>
            <img src={Img} alt="" className={'w-[360px]'}/>
            <fieldset className={'flex flex-col gap-input mx-6 mt-6'}>
                <MJDSelect></MJDSelect>
                <MJDSelect></MJDSelect>
            </fieldset>
            <div className={'flex flex-col gap-4 flex-shrink-0 mx-6'}>
                <span className={'flex'}>
                    <span className={'mr-auto'}>Trial lesson</span>
                    <span>$5.00</span>
                    <span>/ 30 - minutes</span>
                </span>
                <span className={'w-full flex'}>
                    <HubButton text={'Book trial lesson'} leftIcon={'calendar-empty'} buttonStyle={'primary'}></HubButton>
                </span>
                <span className={'w-full flex'}>
                    <HubButton  text={'Send Message'} buttonStyle={'secondary'}></HubButton>
                </span>

            </div>
        </div>
    )
}

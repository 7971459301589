import {HubCheckbox} from "../../atoms/inputs/Checkbox/Checkbox";
import {Icon} from "../../atoms/adornments/Icon/Icon";
import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {HubButton} from "../../atoms/inputs/Button/Button";
import React, {useEffect} from "react";
import {number} from "prop-types";
import {TimePairs} from "./TimePairs";

type TimeSpan = {
    start: string | undefined;
    end: string | undefined;
}

export function TimetableDay({dayName, slots, setSlots}:{dayName: string, slots:any[], setSlots:Function}) {

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={'flex flex-col py-3 px-4 border border-outline rounded-lg gap-6'}>
            <div onClick={() => setIsOpen(!isOpen)}
                 className={'flex gap-input justify-between cursor-pointer'}>
                <HubCheckbox value={isOpen} onChange={(day: boolean) => setIsOpen(!isOpen)}>
                    <span className={'text-body-16 font-semibold text-grey-41'}>{dayName}</span>
                    {slots.map((item, index) =>
                        <span key={index}>
                            <span className={'text-body-16 text-grey-7D'}>{item.start} - {item.end}</span>
                            {index < slots.length - 1 &&
                                <span className={'text-body-16 text-grey-7D'}>•</span>}
                        </span>
                    )}
                </HubCheckbox>
                {!isOpen &&
                    <Icon name={'down_arrow_small'} color={'var(--grey-41-color)'} iconStyle={'Regular'}></Icon>}
                {isOpen &&
                    <Icon name={'down_up_small'} color={'var(--grey-41-color)'} iconStyle={'Regular'}></Icon>}
            </div>
            {slots.length > 0 && isOpen &&
                <div className={'flex flex-col gap-3'}>
                    {slots.map((item, index) => (
                        <TimePairs key={index} index={index} slots={slots} setSlots={setSlots}/>
                    ))}
                </div>
            }
            {isOpen && <span className={'flex'}>
                    <HubButton
                        disabled={slots.length > 0 && (!slots[slots.length - 1]?.start || !slots[slots.length - 1]?.end) ? true : false}
                        text={'Add time span'} buttonStyle={'secondary'} onClick={() => {
                        setSlots([...slots, {start:undefined, end:undefined} as TimeSpan])
                    }}></HubButton>
                </span>}
        </div>
    )
}

import React, {useContext} from "react";
import {ReactNode} from "react";
import {NavHeader} from "../../molecules/NavHeader/NavHeader";
import {SignupNav} from "../../molecules/SignupNav/SignupNav";
import {Main} from "../Main/Main";
import {SignupContext} from "../../providers/Signup/SignupContext";
import {useNavigate} from "react-router-dom";
import {useFormContext} from 'react-hook-form';
import {UserSignupContext, UserSignupProvider} from "../../providers/Signup/UserSignupContext";

export const SignupLayout = ({children}:{children?: ReactNode}) => {
    const {refreshUser} = useContext(UserSignupContext);

    let {
    handleSubmit, control, setError, clearErrors, formState: { errors }
     } = useFormContext();

    const signupContext = useContext(SignupContext);
    const stages = signupContext.stages;

    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        console.log('DATA:', data);
        console.log('STAGE:', signupContext.currentStage, stages, stages[signupContext.currentStage]);
        if (stages[signupContext.currentStage].validate) {
            // @ts-ignore
            const validateReturn = await stages[signupContext.currentStage].validate(data);
            // @ts-ignore
            const valid = validateReturn.value;
            console.log('VALID:', valid);
            if (valid && stages[signupContext.currentStage].submit) {
                console.log('SUBMITTING:', valid);
                // @ts-ignore
                await stages[signupContext.currentStage].submit(data);
                signupContext.markAsCompleted(signupContext.currentStage);
                signupContext.setCurrentStage(signupContext.currentStage+1);
                //alert(stages[signupContext.currentStage+1].url);
                navigate('/signup'+stages[signupContext.currentStage+1].url);
                refreshUser();
            }
            if (!valid) {
                setError(validateReturn.key??'', { type: "custom", message: validateReturn.message });
            }
        }
    }

    return (
        <>
            <NavHeader type={'breadcrumb'} links={stages} />
            <form onSubmit={handleSubmit(onSubmit)} >
                <Main>{children}</Main>
                <SignupNav />
            </form>
        </>
    );
}

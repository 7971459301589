import {PageHeader} from "../../layout/PageHeader/PageHeader";
import React, {useContext, useEffect, useMemo} from "react";
import {Controller, useFormContext} from "react-hook-form";
import ToggleButtons from "../../atoms/inputs/ToggleButtons/ToggleButtons";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import {UserSignupContext} from "../../providers/Signup/UserSignupContext";
import {Shimmer} from "../../atoms/Shimmer/Shimmer";

const languages = [
    {
        name: 'English',
        flagName: 'British',
    },
    {
        name: 'German',
        flagName: 'German',
    },
    {
        name: 'French',
        flagName: 'French',
    },
    {
        name: 'Spanish',
        flagName: 'Spanish',
    },
    {
        name: 'Japanese',
        flagName: 'Japanese',
    },
    {
        name: 'Greek',
        flagName: 'Greek',
    },
    {
        name: 'Italian',
        flagName: 'Italian',
    },
    {
        name: 'Korean',
        flagName: 'South-Korean',
    },
    {
        name: 'Ukranian',
        flagName: 'Ukranian',
    },
];

export function TeachSelectionPage() {
    let { reset, getValues, watch, handleSubmit, control, setValue,  clearErrors } = useFormContext();

    const { formState: {errors} } = useFormContext();

    const {user} = useContext(UserSignupContext);

    useEffect(() => {
        if (user) {
            console.log('taughtLanguages:', user);
            reset({ taughtLanguages: user.taughtLanguages });
        }
    }, [user]);

    return (
        <>
                <PageHeader>
                    <h1>What languages do you teach?</h1>
                    <span className={`${errors?.taughtLanguages?.message&&'text-error'}`}>Choose at least one language you plan to teach.</span>
                </PageHeader>
            <section>
                <div className={'flex flex-col bg-white py-8 px-6 rounded gap-6'}>
                    <h2>The languages you teach:</h2>
                    <div className={'grid grid-cols-3 gap-2 '}>
                        <Controller
                            control={control}
                            name={'taughtLanguages'}
                            defaultValue={[]}
                            rules={{validate: value => value.length > 0 || 'You must select at least one item.'}}
                            render={({
                                         field: {name, value, onChange, onBlur, ref},
                                         fieldState: {invalid, error}
                                     }: any) => (
                                <ToggleButtons defaultValue={value}
                                               onChange={onChange} isLoading={user?false:true}
                                               itemOptions={languages.map((item) => item.name)}
                                               renderButton={(item, isSelected, toggleItem, index) => (
                                                   <div className={`flex items-center justify-center`}>
                                                       <Shimmer isLoading={user?false:true}>
                                                           <button type={'button'} onClick={() => toggleItem(item)}
                                                                   className={`w-full py-3 flex items-center justify-center rounded hover:bg-primary-hover cursor-pointer border-solid border-[1px] hover:border-primary ${isSelected ? 'bg-primary-hover border-primary' : 'bg-bg border-white'}`}>
                                                               <label className={'flex gap-2'}>
                                                                   <DynamicSvgRenderer name={languages[index].flagName}
                                                                                       style={'Flags'}/>
                                                                   {item}
                                                               </label>
                                                           </button>
                                                       </Shimmer>
                                                   </div>
                                               )}/>
                            )}/>
                    </div>
                </div>
            </section>
        </>
    )
}

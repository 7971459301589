import React from "react";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";

export function Schedule() {
    return (
        <div className={'bg-white rounded py-8 px-4 flex flex-col gap-6'}>
            <header className={'flex gap-6 items-center mx-auto'}>
                <DynamicSvgRenderer name={'angle-left-small'} color={'black'} style={'Regular'} />
                <h2 className={'flex items-center gap-1'}><DynamicSvgRenderer name={'calendar'} color={'black'}/> 29 May
                    - 4 Junе 2023</h2>
                <DynamicSvgRenderer name={'angle-right-small'} color={'black'} style={'Regular'} />
            </header>
            <table className={'flex flex-col gap-4'}>
                <thead className={'flex flex-col w-full'}>
                    <tr className={'h-10 flex gap-[14px] w-full justify-between text-center'}>
                        <th className={'w-[96px] text-center'}>Mon</th>
                        <th className={'w-[96px] text-center'}>Tue</th>
                        <th className={'w-[96px] text-center'}>Wed</th>
                        <th className={'w-[96px] text-center'}>Thu</th>
                        <th className={'w-[96px] text-center'}>Fri</th>
                        <th className={'w-[96px] text-center'}>Sat</th>
                        <th className={'w-[96px] text-center'}>Sun</th>
                    </tr>
                    <tr className={'h-10 flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[96px] text-center'}>1</td>
                        <td className={'w-[96px] text-center'}>2</td>
                        <td className={'w-[96px] text-center'}>3</td>
                        <td className={'w-[96px] text-center'}>4</td>
                        <td className={'w-[96px] text-center'}>5</td>
                        <td className={'w-[96px] text-center'}>6</td>
                        <td className={'w-[96px] text-center'}>7</td>
                    </tr>
                </thead>
                <tbody className={'flex flex-col gap-2 w-full'}>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                    <tr className={'flex gap-[14px] w-full justify-between text-center'}>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}>00:00</td>
                        <td className={'w-[82px] text-center'}></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

import {Controller} from "react-hook-form";
import {MJDSelect, HubSelectFieldProps} from "./MJDSelect";
import React from "react";

type HookSelectFieldProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: any;
} & HubSelectFieldProps;

type AriaFieldProps = {
    onChange: any,
    value: string
} & HubSelectFieldProps;

type HubSelectProps = (HookSelectFieldProps | AriaFieldProps) & {isLoading?: boolean};

export const HookSelectField = (props: HubSelectProps) => {
    if ('onChange' in props && 'value' in props) {
        return (
            <MJDSelect {...props} />
        )
    }
    else if ('name' in props && 'control' in props) {
        const {name, control, defaultValue, rules={}, ...rest} = props;
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState: {invalid, error} }) => (
                    <MJDSelect
                        {...field}
                        invalid={invalid}
                        error={error}
                        {...rest}
                    />
                )}
                rules={rules}
            />
        )
    }
    else {
        throw new Error('Invalid props for HookTextField');
    }
}

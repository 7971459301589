import React, { useState, useEffect } from 'react';
import {Shimmer} from "../../Shimmer/Shimmer";

// Helper type to determine if a type is an object (non-array)
type IsObject<T> = T extends object ? (T extends any[] ? false : true) : false;

// Conditional props: require identifierKey only if T is an object
type ToggleButtonsProps<T> = {
    defaultValue: T[];
    onChange: (newValue: T[]) => void;
    itemOptions: T[];
    renderButton?: (item: T, isSelected: boolean, toggleItem: (item: T) => void, index: number) => React.ReactNode;
    identifierKey?: keyof T;
    isLoading?: boolean;
} & (IsObject<T> extends true ? { identifierKey: keyof T } : {});


function ToggleButtons<T>({ isLoading, defaultValue, onChange, itemOptions, identifierKey, renderButton }: ToggleButtonsProps<T>) {
    const [selectedItems, setSelectedItems] = useState<T[]>(defaultValue);

    useEffect(() => {
        console.log('SETTING DEFAULT VALUE', defaultValue);
        setSelectedItems(defaultValue);
    }, [defaultValue]);

    const toggleItem = (item: T) => {
        console.log('ITEM', item);
        let newItems: T[] = [];
        if (typeof item === 'object' && item && identifierKey) {
            const itemIndex = selectedItems.findIndex(selected => selected[identifierKey] === item[identifierKey]);
            newItems = itemIndex >= 0
                ? [...selectedItems.slice(0, itemIndex), ...selectedItems.slice(itemIndex + 1)]
                : [...selectedItems, item];
        } else {
            newItems = selectedItems.includes(item as any)
                ? selectedItems.filter(i => i !== item)
                : [...selectedItems, item];
        }
        console.log('NEW ITEMS', newItems);
        setSelectedItems(newItems);
        onChange(newItems);
    };

    return (
        <>
            {itemOptions.map((item, index) => {
                const isSelected = typeof item === 'object' && identifierKey
                    ? selectedItems.some(selected => item && selected[identifierKey] === item[identifierKey])
                    : selectedItems.includes(item);

                return renderButton
                    ? renderButton(item, isSelected, () => toggleItem(item), index)
                    : (
                        <Shimmer isLoading={isLoading??false} key={index}>
                            <button
                                key={typeof item === 'object' && item && identifierKey ? item[identifierKey] as React.Key : index}
                                type="button"
                                onClick={() => toggleItem(item)}
                                style={{backgroundColor: isSelected ? 'green' : 'grey'}}
                            >
                                {(typeof item === 'object' && item && identifierKey ? item[identifierKey] : item) as string | number | boolean}
                            </button>
                        </Shimmer>
                    );
            })}
        </>
    );
}

export default ToggleButtons;

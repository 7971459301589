import React, { useState, useEffect } from 'react';
import {IconStyle} from "../../types";

function DynamicSvgRenderer({ name, size = 24, color = 'red', style = 'Filled' } : { name: string, size?: number, color?: string, style?: IconStyle }) {
    const [svg, setSvg] = useState(null as any);

    useEffect(() => {
        const importSvg = async () => {
            try {
                const ReactComponent = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${style}/${name}.svg`)).default;
                if (!ReactComponent) {
                    throw new Error(`Cannot find SVG component '../../assets/${name}.svg'`);
                }
                setSvg(<ReactComponent width={size} height={size} fill={color} />);
            } catch (error) {
                console.error(`Error importing SVG: ${error}`);
            }
        };

        importSvg();
    }, [name, size, color]);

    return svg;
}

export default DynamicSvgRenderer;

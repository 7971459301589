import { Children, isValidElement, ReactNode } from 'react';

export function cx(
    ...classNames: Array<string | number | boolean | undefined | null>
) {
    return classNames.filter(Boolean).join(' ');
}

export function capitalize(value: string) {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getFirstChildPropValue(
    children: ReactNode,
    propNameCb: (props: any) => string
): string | string[] | undefined {
    let propValue = undefined;

    Children.forEach(children, (element) => {
        if (!isValidElement(element)) return;
        const propName = propNameCb(element.props);
        if (propName in element.props) {
            propValue = element.props[propName];
            return;
        }
    });

    return propValue;
}

export const monthNamesOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const getCurrentYear = () => new Date().getFullYear();
export const yearsSince = (startYear:number) => Array.from({length: getCurrentYear()-startYear+1}, (v, k) => (getCurrentYear() - k).toString());
export const twoDigits = (num: number) => num < 10 ? '0' + num : num;
export const indexOfMonth = (month: string) => monthNamesOfYear.indexOf(month);
export const daysInMonth = (month: string|number, year: string|number) => {
    if (!month || !year) return 31;
    year = parseInt(year.toString());
    month = parseInt(month.toString())?parseInt(month.toString()):indexOfMonth(month.toString())+1;
    return new Date(year, month, 0).getDate();
}

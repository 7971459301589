import {useState} from "react";

export function useLocalStorage(name: string) {
    const getFromLocalStorage = () => {

        return {};
        /*const local = localStorage.getItem(name);
        if (local) {
            return JSON.parse(local);
        }
        else {
            return {};
        }*/
    }

    const [value, set] = useState<any>(getFromLocalStorage());

    const setValue = (value: object) => {
        localStorage.setItem(name, JSON.stringify(value));
        set(value);
    }

    return {value, setValue};
}

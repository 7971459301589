import React from "react";
import "./Reviews.scss";
import {Review} from "../../molecules/Review/Review";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import Img from '../../assets/images/Google_Translate.svg';


export function Reviews() {
    return (
        <div className={'bg-white flex flex-col gap-8 px-6 py-8'}>
            <div className={'flex flex-col gap-6'}>
                <div className={'flex gap-2'}>
                    <img src={Img} alt=""/>
                    <span>Translated with google translate.</span>
                    <span className={'text-primary'}>Show original</span>
                </div>
                <hr />
            </div>
            {[...Array(5)].map((e, i) =>
                <>
                    {i !== 0 && <hr/>}
                    <Review key={i} />
                </>
            )}
            <span className={'text-primary mx-auto flex gap-2'}>
                <span>Show more reviews (28)</span>
                <DynamicSvgRenderer name={'down_arrow_small'} style={'Regular'}></DynamicSvgRenderer>
            </span>
        </div>
    )
}

import {HubButton} from "../../atoms/inputs/Button/Button";
import React from "react";

export function RegistrationSuccessful() {
    return (
        <div className={'flex flex-col gap-6'}>
            <div className={'flex flex-col gap-4 text-center'}>
                <h1>Registration Successful!</h1>
                <p>Your tutor account is now undergoing our verification process.
                    You'll receive an email update once the review is complete.</p>
            </div>
            <span className={'w-full flex'}>
                <HubButton text={'Welcome'}/>
            </span>
        </div>
    )
}

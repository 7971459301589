import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../../providers/Signup/AuthContext";

type APISourceParams = {resourceType: string, resourceName: string, method: 'POST' | 'PUT' | 'GET' | 'DELETE', body ? : string, requestHeaders?: any}
type APIParams = {headers:any, body?:any, credentials?: RequestCredentials | undefined, method: 'POST' | 'PUT' | 'GET' | 'DELETE',}

export const useApi = () => {
    const navigate = useNavigate();
    const {logout, accessTokenString} = useContext(AuthContext);

    const callProtectedApi = async ({ resourceType, resourceName, method = 'GET', body, requestHeaders }:APISourceParams) => {
        try {
            const headers = requestHeaders??{
                'Content-Type': 'application/json',
            };
            if (process.env.REACT_APP_API === 'DEV') {
                headers.Authorisation = `Bearer ${accessTokenString}`;
            }
            const params:APIParams = {
                method,
                headers,
            };
            if (process.env.REACT_APP_API === 'PROD') {
                params.credentials = 'include';
            }
            if (body) {
                params.body = body;
            }
            const response = await fetch(`${process.env.REACT_APP_API ?? ''}/api/${resourceType}/${resourceName}`, params);
            if (method === 'GET') {
                console.log('PROTECTED API RESPONSE', response);
                if (response.status === 401) {
                    // @ts-ignore
                    logout && logout();
                    navigate('/');
                }
                return await response.json();
            }
            return true;
        } catch (e: any) {
            console.log('error', e);
            return false;
        }
    }

    return { callProtectedApi };
}

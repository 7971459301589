import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {HubButton} from "../../atoms/inputs/Button/Button";
import './payments.scss';
import PaymentStatus from "./PaymentStatus";
import {ToastContext} from "../../molecules/Toast/ToastProvider";

type StripePaymentOptions = {
    mode: 'payment';
    amount: number;
    currency: string;
    appearance: {
        /*...*/
    };
};

export const CheckoutForm = ({closeModal}:{closeModal:any}) => {
    const [secret, setSecret] = useState(undefined as any | undefined);
    const [balance, setBalance] = useState(undefined as any | undefined);

    const {addSuccessToast, addErrorToast} = useContext(ToastContext);

    const stripePromise = loadStripe("pk_test_51MUt98Jwj1AHZknuWrUlpiAXA3q05h94SzrDHF2SMZOMxxezeC2Du5Egxwqf70rLU7POAEIaDYV9BQ985xpJ8AvU00RitpBEwZ");

    const fetchIntent = async (items:any) => {
        const res = await fetch("https://languagehub.michaeljdfreelance.com/api/payments", {
            method: "POST",
            credentials: 'same-origin',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items }),
        });
        return res.json();
    }

    const fetchBalance = async () => {
        const apiUrl = 'https://languagehub.michaeljdfreelance.com/api/payments/test';
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json', // Specify the content type if necessary
                'Authorization': 'Bearer YOUR_ACCESS_TOKEN', // Include authorization header if needed
            },
        });
        return response.json();
    }

    useEffect(() => {
        console.log('fetching secret');
        const storeClientSecret = async () => {
            const items = [{ id: "xl-tshirt" }];
            const {clientSecret} = await fetchIntent(items);
            console.log('secret', clientSecret);
            setSecret(clientSecret);
        }
        const storeBalance = async () => {
            const {amount} = await fetchBalance();
            setBalance(amount);
        }
        storeClientSecret();
        storeBalance();
    }, []);

    /*const options = {
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {
            /!*...*!/
            theme: 'stripe',

            variables: {
                colorPrimary: 'rgb(254 83 83)',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: '#df1b41',
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                spacingUnit: '2px',
                borderRadius: '4px',
                // See all possible variables below
            },

            rules: {
                '.Tab': {
                    border: '1px solid #E0E6EB',
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                },

                '.Tab:hover': {
                    color: 'var(--colorText)',
                },

                '.Tab--selected': {
                    borderColor: '#E0E6EB',
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
                },

                '.Input--invalid': {
                    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
                },

                // See all supported class names and selector syntax below
            }
        }
    } as StripePaymentOptions;*/

    const formattedBalance = (balanceInDollars:any) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(balanceInDollars);
    }

    const increaseBalance = (amount:any) => {
        setBalance(balance + amount);
        addSuccessToast && addSuccessToast('Payment successful. Your new balance is ' + formattedBalance((balance + amount)/100));
        closeModal();
    }

    return (
        <>
            <div>
                {balance && <h2>Balance: {formattedBalance(balance/100)}</h2>}
            </div>
            {secret && <Elements stripe={stripePromise} options={{
                // passing the client secret obtained from the server
                clientSecret: secret,
            }}>
                <Checkout onPaymentSuccess={increaseBalance} />
            </Elements>}
        </>
    );
}

const Checkout = ({onPaymentSuccess}: { onPaymentSuccess: (amount: Number) => void }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(undefined as string | undefined);

    const message = PaymentStatus((message:string)=>alert(message));

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError?.message);
            return;
        }



        if (stripe) {
            const {error, paymentIntent} = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                redirect: 'if_required',
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setErrorMessage(error.message);
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                console.log('paymentIntent', paymentIntent);
                onPaymentSuccess(paymentIntent.amount);

            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className={'flex flex-col gap-[48px]'}>
            <div className={'w-[367px]'}>
                <PaymentElement className={'flex flex-col'} />
            </div>
            <div>
                <HubButton text={'Pay'} type="submit" disabled={!stripe || !elements} />
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
            {message && <div>{message}</div>}
        </form>
    );
};

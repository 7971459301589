import { Route, Routes } from "react-router-dom";
import { ProfileEdit } from "../../pages/ProfileEdit/ProfileEdit";
import { LanguageSelectionPage } from "../../pages/LanguageSelectionPage/LanguageSelectionPage";
import { TeachSelectionPage } from "../../pages/LanguageSelectionPage/TeachSelectionPage";
import { Preferences } from "../../pages/Preferences/Preferences";
import { LessonSchedule } from "../../pages/LessonSchedule/LessonSchedule";
import { Verification } from "../../pages/Verification/Verification";
import { Success } from "../../pages/Success/Success";
import React, { useEffect, useState } from "react";
import { SignupProvider } from "../../providers/Signup/SignupContext";
import { SignupLayout } from "./SignupLayout";
import {useForm, useFormContext} from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { Search } from "../../pages/Search/Search";
import { UserSignupProvider } from "../../providers/Signup/UserSignupContext";

export function Signup() {
    const profileForm = useForm();
    const preferencesForm = useForm();
    const teachForm = useForm();
    const languageForm = useForm();
    const scheduleForm = useForm();
    const verificationForm = useForm();

    const [signupType, setSignupType] = useState('');

    let form = useFormContext();

    useEffect(() => {
        const userInfoString = sessionStorage.getItem('IdInfo');
        const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
        setSignupType(userInfo['custom:registration_type']);
    }, []);

    return (
        <SignupProvider index={0}>
            <UserSignupProvider>
                {signupType === 'learning' ?<> <Routes>
                    <Route path="/profile" element={<FormProvider {...profileForm}><SignupLayout {...form} ><ProfileEdit/></SignupLayout></FormProvider>}/>
                    <Route path="/preferences" element={<FormProvider {...preferencesForm}><SignupLayout><Preferences/></SignupLayout></FormProvider>}/>
                    <Route path="/search" element={<Search />}/>
                </Routes></>:<><Routes>
                    <Route path="/teach-select" element={<FormProvider {...teachForm}><SignupLayout><TeachSelectionPage/></SignupLayout></FormProvider>}/>
                    <Route path="/language-select" element={<FormProvider {...languageForm}><SignupLayout><LanguageSelectionPage/></SignupLayout></FormProvider>}/>
                    <Route path="/lesson-schedule" element={<FormProvider {...scheduleForm}><SignupLayout><LessonSchedule/></SignupLayout></FormProvider>}/>
                    <Route path="/profile" element={<FormProvider {...profileForm}><SignupLayout><ProfileEdit/></SignupLayout></FormProvider>}/>
                    <Route path="/verification" element={<FormProvider {...verificationForm}><SignupLayout><Verification/></SignupLayout></FormProvider>}/>
                    <Route path="/success" element={<Success/>}/>
                </Routes></>}
            </UserSignupProvider>
        </SignupProvider>
    );
}

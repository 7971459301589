import React, {useEffect} from "react";
import {TimetableDay} from "./TimetableDay";

type TimeSpan = {
    start?: string;
    end?: string;
}

type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

type Slots = {
    [key in WeekDays]: {
        name: string,
        times: TimeSpan[],
    };
}

export function Timetable({originalItems, onUpdate}:{originalItems?: any, onUpdate: (timetable: any) => void}){
    const [slots, setSlots] = React.useState<Slots>(originalItems??
        {monday: {name:'Monday', times:[]},
            tuesday: {name:'Tuesday', times:[]},
            wednesday: {name:'Wednesday', times:[]},
            thursday: {name:'Thursday', times:[]},
            friday: {name:'Friday', times:[]},
            saturday: {name:'Saturday', times:[]},
            sunday: {name:'Sunday', times:[]}});

    useEffect(() => {
        setSlots(originalItems);
    }, [originalItems]);

    //console.log('originalItems:', originalItems);

    const updateSlots = (day: string, daySlots: TimeSpan[]) => {
        console.log('Updating slots for day:', day, 'with slots:', daySlots);
        setSlots({...slots, [day]: {...slots[day as keyof typeof slots], times: daySlots}});
        onUpdate({...slots, [day]: {...slots[day as keyof typeof slots], times: daySlots}});
    }

    return (
        <div className={'flex flex-col gap-[23px]'}>
            {Object.keys(slots).map((day) => (
                <TimetableDay key={day} dayName={slots[day as keyof typeof slots].name} slots={slots[day as keyof typeof slots].times} setSlots={(slots: TimeSpan[])=>updateSlots(day, slots)} />
            ))}
        </div>
    )
}

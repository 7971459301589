// @ts-ignore
import type {AriaToastRegionProps} from '@react-aria/toast';
// @ts-ignore
import type {ToastState} from '@react-stately/toast';
// @ts-ignore
import {useToastRegion} from '@react-aria/toast';
import React from "react";
import {Toast} from "./ToastProps";

interface ToastRegionProps<T> extends AriaToastRegionProps {
    state: ToastState<T>;
}

export function ToastRegion<T extends React.ReactNode>(
    { state, ...props }: ToastRegionProps<T>
) {
    let ref = React.useRef(null);
    let { regionProps } = useToastRegion(props, state, ref);

    return (
        <div {...regionProps} ref={ref} className="toast-region z-[10000000]">
            {state.visibleToasts.map((toast: any) => (
                <Toast key={toast.key} toast={toast} state={state} />
            ))}
        </div>
    );
}

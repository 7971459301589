import {NavHeader} from "../../molecules/NavHeader/NavHeader";
import {PageHeader} from "../../layout/PageHeader/PageHeader";
import {PersonalInfo} from "../../organisms/PersonalInfo/PersonalInfo";
import {HubTextField} from "../../atoms/inputs/TextField/TextField";
import {Main} from "../../layout/Main/Main";
import {Fieldset} from "../../layout/Fieldset/Fieldset";
import React, {useContext, useEffect} from "react";
import {FileUpload, FileUploadTrigger} from "../../atoms/inputs/FileUpload/FileUpload";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import {HubButton} from "../../atoms/inputs/Button/Button";
import {HookTextField} from "../../atoms/inputs/TextField/HookTextField";
import {HookTriggerField, HookUploadField} from "../../atoms/inputs/FileUpload/HookFileUpload";
import {useFormContext} from "react-hook-form";
import {AuthContext} from "../../providers/Signup/AuthContext";

const formatBytesSize = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function Certification({user, onClose}:{user?: any, onClose?: any, handleSubmit?: any, control?: any, setValue?: any, getValues?: any}) {
    let form = useFormContext();
    const { reset, control, setValue, getValues, watch, formState: {errors} } = form;

    const [isDeleting, setIsDeleting] = React.useState(false);
    const [file, setFile] = React.useState(getValues().certificate_file as any|undefined);
    const certificate_file = watch('certificate_file');

    useEffect(() => {
        console.log('FILE VALUE:', file, getValues().certificate_file);
        if (getValues().certificate_file && !file) {
            setFile(getValues().certificate_file);
        }
    }, [file]);

    useEffect(() => {
        setFile(user.certificate_file);
    }, [user]);


    const {watch:cognitoWatch} = useContext(AuthContext);
    const cognitoID = watch('ID');


    return (
        <>
            {isDeleting ? <div className={'flex flex-col justify-center items-center w-[448px] py-6 gap-[40px]'}>
                <h2>Delete certificate</h2>
                <div className={'flex-col flex gap-6 justify-center items-center'}>
                    <DynamicSvgRenderer name={'document'} color={'rgba(125, 125, 125, 1)'} size={60} />
                    <span>Are you sure you want to delete the certificate?</span>
                </div>
                <div className={'flex gap-4 '}>
                <HubButton text={'Cancel'} buttonStyle={'secondary'}
                               onClick={() => setIsDeleting(false)}/>
                    <HubButton text={'Delete'} buttonStyle={'primary'}
                               onClick={() => {
                                   reset({certificate_file: undefined});
                                   setIsDeleting(false)
                               }}/>
                </div>
            </div> : <Main>
                <PageHeader>
                <h1 className={'text-title-32 font-bold text-grey-41 text-center'}>Certification</h1>
                </PageHeader>
                <section>
                    <Fieldset>
                        <span className={'col-span-6'}><HookTextField control={control}
                                                                    name={'certificate_language'}
                                                                     placeholder={'Choose language'}
                                                                     labelText={'Language'}/></span>
                        <span className={'col-span-6'}><HookTextField control={control}
                                                                    name={'certificate'}
                                                                     placeholder={'Enter the type of certificate'}
                                                                     labelText={'Certificate'}/></span>
                        <span className={'col-span-3 flex'}><HookTextField control={control}
                                                                          name={'from_year'}
                                                                          placeholder={'From'}
                                                                          labelText={'Years of study'}/></span>
                        <span className={'col-span-3 flex'}><HookTextField control={control}
                                                                          name={"to_year"}
                                                                          placeholder={'To'}
                                                                          labelText={'To'}/></span>
                        <span className={'col-span-6'}><HookTextField control={control}
                                                                    name={'issuer'}
                                                                     placeholder={'Enter by whom the certificate was issued'}
                                                                     labelText={'Issued by'} /></span>
                    </Fieldset>
                    {!certificate_file ? <>
                        <h1 className={'text-title-32 font-bold text-grey-41 text-center'}>Verify your
                            certificate</h1>
                        <span className={'text-body-16 font-bold text-grey-7D'}>Proof of certificate will be displayed on your tutor page.</span>
                        <HookUploadField userID={cognitoID} saveMode={'both'} onFileRef={(file: any) => setFile(file)} control={control}
                                         subBucket={`${cognitoID}/certificate`} name={'certificate_file'} >
                            {/*uploadMessage={'File size should not exceed 10MB. Formats: JPG, PNG.'} uploadText={'Upload Video'}*/}
                            <PageHeader>
                                <div
                                    className={'flex gap-4 border-secondary border-dashed border-2 flex flex-col items-center justify-center h-[240px] rounded rounded-[16px]'}>
                                    <DynamicSvgRenderer name={'upload'} color={'rgba(125, 125, 125, 1)'} size={80}/>
                                    <h2 className={'text-title-24'}>Upload your certificate</h2>
                                    <p>File size should not exceed 10MB. Formats: JPG, PNG.</p>
                                </div>
                            </PageHeader>
                        </HookUploadField>
                        <div className={'flex justify-center'}>
                            <div>
                                <HookTriggerField userID={cognitoID} subBucket={`${cognitoID}/certificate`} saveMode={'both'} control={control} name={'certificate_file'} onFileRef={(file: any) => setFile(file)}>
                                    <HubButton text={'Upload'} buttonStyle={'primary'}
                                               />
                                </HookTriggerField>
                            </div>
                        </div>
                    </> : <>
                        <h1 className={'text-title-32 font-bold text-grey-41 text-center'}>Certification</h1>
                        <div className={'bg-secondary p-4 flex items-center gap-4 rounded rounded-[16px]'}>
                                <span className={'rounded border-solid border-grey border-solid '}>
                                    <DynamicSvgRenderer name={'document'} color={'rgba(65, 65, 65, 1)'} size={24}/>
                                </span>
                            <span className={'mr-auto'}>{certificate_file?.name}</span>
                            <span
                                className={''}>{certificate_file?.name?.split('.').pop().toString().toUpperCase()} • {formatBytesSize(certificate_file?.size)}</span>
                        </div>
                        <div className={'flex justify-center'}>
                            <div className={'flex gap-4'}>
                                <HubButton text={'Delete'} buttonStyle={'secondary'}
                                           onClick={() => setIsDeleting(true)}/>
                                <HubButton disabled={false} text={'Close'} buttonStyle={'primary'}
                                           onClick={onClose}/>
                            </div>
                        </div>
                    </>}
                </section>

            </Main>}
        </>


    )
}

import {Controller} from "react-hook-form";

import React from "react";
import {FileUpload, FileUploadTrigger, HubTriggerFieldProps, HubUploadFieldProps} from "./FileUpload";
import {FileTrigger} from "react-aria-components";

type HookUploadFieldProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: any;
} & HubUploadFieldProps;

type AriaUploadFieldProps = {
    onChange: any,
    value: string
} & HubUploadFieldProps;

type HookTriggerFieldProps = {
    name: string;
    control: any;
    rules?: any;
    defaultValue?: any;
} & HubTriggerFieldProps;

type AriaTriggerFieldProps = {
    onChange: any,
    value: string
} & HubTriggerFieldProps;

type HubUploadProps = (HookUploadFieldProps | AriaUploadFieldProps) & {isLoading?: boolean};
type HubTriggerProps = (HookTriggerFieldProps | AriaTriggerFieldProps) & {isLoading?: boolean};

export const HookUploadField = (props: HubUploadProps) => {
    if ('onChange' in props && 'value' in props) {
        return (
            <FileUpload {...props} />
        )
    }
    else if ('name' in props && 'control' in props) {
        const {name, control, defaultValue, rules={}, ...rest} = props;
        console.log('rest', rest);
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState: {invalid, error} }) => (
                    <FileUpload
                        {...field}
                        invalid={invalid}
                        error={error}
                        {...rest}
                    />
                )}
                rules={rules}
            />
        )
    }
    else {
        throw new Error('Invalid props for HookTextField');
    }
}


export const HookTriggerField = (props: HubTriggerProps) => {
    if ('onChange' in props && 'value' in props) {
        return (
            <FileUploadTrigger {...props} />
        )
    }
    else if ('name' in props && 'control' in props) {
        const {name, control, defaultValue, rules={}, ...rest} = props;
        console.log('rest', rest);
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState: {invalid, error} }) => (
                    <FileUploadTrigger
                        {...field}
                        invalid={invalid}
                        error={error}
                        {...rest}
                    />
                )}
                rules={rules}
            />
        )
    }
    else {
        throw new Error('Invalid props for HookTextField');
    }
}

import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {Stage, useSignupStages} from "./useSignupStages";
import {useNavigate, useLocation } from "react-router-dom";

type TutorSignupInfo = {

}

type StudentSignupInfo = {

}

type SignupInfo = TutorSignupInfo | StudentSignupInfo;

const getIndexOfLastCompleted = (stages: Stage[]) => {
    for (let i = stages.length - 1; i >= 0; i--) {
        if (!stages[i].completed) return i>0?i-1:0;
    }
    return 0;
}

// Create a context
export const SignupContext = createContext({ signupType: '',
                                                            handleSignupType: ((type: string)=>{}) as ((type: string) => void),
                                                            signupInfo: undefined as SignupInfo | undefined,
                                                            setSignupInfo: undefined as ((value: SignupInfo) => void) | undefined,
                                                            markAsCompleted: ((index: number)=>{}) as((index: number) => void),
                                                            stages: [] as Stage[],
                                                            currentStage: 0,
                                                            setCurrentStage: ((index: number)=>{}) as ((index: number) => void),
                                                            submitDisabled: false,
                                                            setSubmitDisabled: ((value: boolean) => {}) as ((value: boolean) => void)
                                                        });

// Create a provider component
export const SignupProvider = ({ children, index=0 }: {children: ReactNode, index:number}) => {
    const {learningStages, teachingStages} = useSignupStages();

    const [signupType, setSignupType] = useState('');
    const [signupInfo, setSignupInfo] = useState<any>({});
    const [stages, setStages] = useState<Stage[]>([]);
    const [currentStage, setCurrentStage] = useState<number>(0);
    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const userInfoString = sessionStorage.getItem('IdInfo');
        const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
        console.log('USER INFO:', userInfo, userInfo['custom:registration_type']);
        setSignupType(userInfo['custom:registration_type']);
        const stages = getStages(userInfo['custom:registration_type']);
        const indexOfLastCompleted = getIndexOfLastCompleted(stages);
        console.log('INDEX OF LAST COMPLETED:', indexOfLastCompleted, stages);
        if (indexOfLastCompleted < index) navigate('/signup'+stages[indexOfLastCompleted].url);
        const storedFirstString = sessionStorage.getItem('currentStage');
        const storedFirst = storedFirstString ? parseInt(storedFirstString) : 0;
        const newStages = stages.map((stage, i) => {
            if (i < storedFirst) return {...stage, completed: true};
            return stage;
        });
        setStages(newStages);
        const currentStage = newStages.findIndex((stage) => location.pathname.includes(stage.url));
        setCurrentStage(currentStage);
    }, []);

    const handleSignupType = (type: string) => {
        setSignupType(type);
    };

    const mergeArrayByIndex = (arr1: any[], arr2: any[]) => {
        return arr1.map((item, i) => {
            return {...item, ...arr2[i]};
        });
    }

    const getStages = (type: string) => {
        let storedStages;
        const sessionStages = sessionStorage.getItem('stages');
        if (sessionStages) {
            const parsedStages = JSON.parse(sessionStages);
            if (Array.isArray(parsedStages)) storedStages = parsedStages;
        }
        const stages = type === 'teaching' ? teachingStages : learningStages;
        return storedStages?mergeArrayByIndex(stages, storedStages):stages;
    }

    const markAsCompleted = (index: number) => {
        const newStages = stages.map((stage, i) => {
            if (i === index) return {...stage, completed: true};
            return stage;
        });
        console.log('NEW STAGES:', newStages);
        setStages(newStages);
        sessionStorage.setItem('stages', JSON.stringify(newStages));
    }

    return (
        <SignupContext.Provider value={{ submitDisabled, setSubmitDisabled, signupType, handleSignupType, signupInfo, setSignupInfo, stages, markAsCompleted, currentStage, setCurrentStage }}>
            {children}
        </SignupContext.Provider>
    );
};

import {ChangeEvent, Reducer, useReducer} from "react";

export const useArray = <T extends object>(initialValue: T[]) => {
    // birdInfoReducer.js
    const actionTypes = {
        ADD: 'ADD_ELEMENT',
        DELETE: 'DELETE_ELEMENT',
        UPDATE: 'UPDATE_ELEMENT',
    };

    type addAction = {
        type: 'ADD_ELEMENT',
        payload: T,
    };

    type deleteAction = {
        type: 'DELETE_ELEMENT',
        payload: number,
    };

    type updateAction = {
        type: 'UPDATE_ELEMENT',
        payload: { index: number, fieldName: keyof T, fieldValue: any },
    };

    type U = T | number | { index: number, fieldName: keyof T, fieldValue: any };

    type modifier = addAction | deleteAction | updateAction;

    const reducer = (state: T[], action: any) => {
        switch (action.type) {
            case actionTypes.ADD:
                return [...state, action.payload];
            case actionTypes.DELETE:
                return state.filter((_: any, index: number) => index !== action.payload);
            case actionTypes.UPDATE:
                return state.map((element: T, index: number) => {
                    const _action = action as updateAction;
                    if (index === (_action as updateAction).payload.index) {
                        return {
                            ...element,
                            [_action.payload.fieldName]: _action.payload.fieldValue,
                        };
                    }
                    return element;
                });
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer<Reducer<T[], modifier>>(reducer, initialValue);

    const callDispatch = (action: modifier) => {
        dispatch(action);
    }

    const addItem = (item: T) => {
        callDispatch({
            type: 'ADD_ELEMENT',
            payload: item,
        });
    }

    const deleteItem = (index: number) => {
        callDispatch({
            type: 'DELETE_ELEMENT',
            payload: index,
        });
    }

    function useField(fieldName: keyof T, index: number, control: "text" | "checkbox" | "radio" | "select" | "textarea" | "custom" = "text") {

        console.log("useField", "state:", state, "fieldName:", fieldName, "index:", index, "control:", control);

        const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        }

        const onChange = (fieldValue: any) => {
            callDispatch({
                type: 'UPDATE_ELEMENT',
                payload: { index, fieldName, fieldValue },
            });
        }

        const getChangeHandler = () => {
            switch (control) {
                case "text":
                    return onTextChange;
                default:
                    return onChange;
            }
        }

        return {value: state[index][fieldName]??"", onChange: getChangeHandler()};
    }

    function useSelectField(fieldName: keyof T, index: number) {
        const onChange = (value: any) => {
            callDispatch({
                type: 'UPDATE_ELEMENT',
                payload: { index, fieldName, fieldValue:value },
            });
        }


        console.log("useField", "state:", state, "fieldName:", fieldName, "index:", index);

        return {value: state[index][fieldName]??"", onChange};
    }

    return {state, useField, useSelectField, addItem, deleteItem};
}

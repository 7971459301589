import React from "react";
import { RegistrationSuccessful } from "../../organisms/RegistrationSuccessful/RegistrationSuccessful";
import { NavHeader } from "../../molecules/NavHeader/NavHeader";
import { SignupNav } from "../../molecules/SignupNav/SignupNav";
import JSConfetti from 'js-confetti'

export function Success() {

    const jsConfetti = new JSConfetti();
    jsConfetti.addConfetti()

    setInterval(() => {

    }, 10000);

    return (
        <>
            <NavHeader type={'blank'} links={[{name:'Teach language', url:'/teach-select'}, {name:'Speak language', url:'/language-select', current:true}, {name:'Your Schedule', url:'/lesson-schedule'}, {name:'Profile', url:'/setup'}, {name:'Verification', url:'/verification'}, {name:'Success!', url:'/success'}]} />
            <div className={'absolute h-screen top-[0px] flex items-center justify-center w-full'}
                 style={{zIndex: 100}}>
                <div className={'bg-white rounded-[16px] px-[400px] py-[200px] z-10000 w-[1500px]'}>
                    <RegistrationSuccessful/>
                </div>
            </div>
            <SignupNav/>
        </>
    )
}

import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {Timetable} from "../../molecules/Timetable/Timetable";
import {HubButton} from "../../atoms/inputs/Button/Button";
import {PageHeader} from "../../layout/PageHeader/PageHeader";
import {useArray} from "../../hooks/useArray";
import React, {useContext, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {UserSignupContext} from "../../providers/Signup/UserSignupContext";

const listTimeZones = () => {
    return [
        'Eastern Time (ET)',
        'Central Time (CT)',
        'Mountain Time (MT)',
        'Pacific Time (PT)',
        'Alaska Time (AT)',
        'Hawaii Time (HT)',
    ]
}

const putSelection = async (value:any) => {
    console.log('putting selection');
    const updateData = value;
    try {
        const response = await fetch(`${process.env.REACT_APP_API??''}/api/dynamo/tutors`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${sessionStorage.getItem('accessToken')}`
            },
            body: JSON.stringify({updateData}),
        });
        return true;
    }
    catch (e:any) {
        console.log('error', e);
        return false;
    }
}


export function LessonSchedule({index}:{index?:number}) {
    const {state, useField} = useArray<{ name: string, level: string }>([]);

    const {reset, handleSubmit, control, setValue, clearErrors, formState: { errors } } = useFormContext();

    const [timeZone, setTimeZone] = React.useState<any>([]);
    const [timetable, setTimetable] = React.useState<any>({"monday":{"name":"Monday","times":[]},"tuesday":{"name":"Tuesday","times":[]},"wednesday":{"name":"Wednesday","times":[]},"thursday":{"name":"Thursday","times":[]},"friday":{"name":"Friday","times":[]},"saturday":{"name":"Saturday","times":[]},"sunday":{"name":"Sunday","times":[]}});

    const {user} = useContext(UserSignupContext);

    useEffect(() => {
        const {monday, tuesday, wednesday, thursday, friday, saturday, sunday} = user?.timetable??user?.timetable??{"monday":{"name":"Monday","times":[]},"tuesday":{"name":"Tuesday","times":[]},"wednesday":{"name":"Wednesday","times":[]},"thursday":{"name":"Thursday","times":[]},"friday":{"name":"Friday","times":[]},"saturday":{"name":"Saturday","times":[]},"sunday":{"name":"Sunday","times":[]}};
        setTimetable({monday, tuesday, wednesday, thursday, friday, saturday, sunday});
        console.log('taughtLanguages:', user);
        setTimeZone(user?.userTimeZone??[]);
    }, [user]);

    useEffect(() => {
        console.log("timetable", JSON.stringify(timetable));
        setValue('timetable', timetable);
    }, [timetable]);

    useEffect(() => {
        setValue('timezone', timeZone);
    }, [timeZone]);

    return (
        <>
                <PageHeader>
                    <h1>Lesson schedule</h1>
                    <span>Please choose a schedule that is convenient for you. </span>
                </PageHeader>
                <section>
                    <h2 className={'text-title-24 '}>Your time zone:</h2>
                    <span className={'col-span-12'}><MJDSelect items={listTimeZones()} value={timeZone} onChange={setTimeZone} /></span>
                </section>
                <section>
                    <h2>Weekly schedule:</h2>
                    <Timetable onUpdate={setTimetable} originalItems={timetable} />
                </section>
                {false &&
                    <section>
                    <h2>Sync schedule to Google Calendar</h2>
                    <div>
                        <HubButton buttonStyle={'secondary'} text={'Connect to Google Calendar'} leftIcon={'Google'}
                                   leftIconStyle={'Logos'}/>
                    </div>
                </section>}
        </>
    )
}

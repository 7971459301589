import React, {ReactNode} from "react";
import './Shimmer.css';

export const Shimmer = ({isLoading=true, children}:{isLoading:boolean, children: ReactNode}) => {
    return (
        <div className={'h-full w-full relative overflow-hidden bg-white'}>
            {isLoading && <div className={'shimmer'}/>}
            <div className={`${isLoading?'invisible':'visible'}`}>
                {children}
            </div>
        </div>)
}

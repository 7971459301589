import React, { createContext, ReactNode, useEffect } from 'react';
import {CognitoAccessors, useCognito} from "../../hooks/aws/cognito/useCognito";
import {FieldValues, Path, useForm, UseFormReturn, UseFormWatch} from "react-hook-form";
import {navigate} from "@storybook/addon-links";
import {useNavigate, useLocation } from "react-router-dom";

type authContextProps = {
    control?: any;
    passwordField: any;
    codeField: any;
    useCognitoDateFieldWrapper: any;
    useCustomCognitoField: any;
    useField: (name: string) => { control:any, name:string };
    watch?: UseFormWatch<any>;
    isLoading: boolean;
    setCode: (code: string) => void;
    setUserType: (userType: string) => void;
    valueUpdated?: (value: any) => void;
} & CognitoAccessors;

type CognitoFields = {
    name: string,
    family_name: string,
    custom: any;
}

// Create a context
export const AuthContext = createContext({ isLoading: true, isLoggedIn: false, logout: undefined } as authContextProps);

// Create a provider component
export const AuthProvider = ({ children }: {children: ReactNode}) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // call verify on API
        // if logged in set is logged in to true
        // otherwise navigate to login
        const userInfoString = sessionStorage.getItem('IdInfo');
        const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
        if (!userInfoString && window.location.pathname !== '/') {
            navigate('/');
        }
        setIsLoading(false);
    }, []);

    const loadUsers = () => {
        const userInfoString = sessionStorage.getItem('IdInfo');
        const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
        return userInfo;
    }

    const form = useForm<CognitoFields>({
        defaultValues: loadUsers(),
    });

    const { control, getValues, watch, reset } = form;

    const formatCustom = (fields: any) => {
        console.log('LOGIN FORMATTING COGNITO VALUES:', fields);
        const {custom, backingFields, ...rest} = fields;
        console.log('LOGIN FORMATTING CUSTOM:', custom);
        if (custom) {
            console.log('LOGIN FORMATTING CUSTOM:', custom);
            for (let key of Object.keys(custom)) {
                rest['custom:'+key] = custom[key];
            }
        }

        return rest;
    }

    const { control:secureControl, getValues:getSecureValues, setValue:setSecureValue } = useForm({
        defaultValues: {
            password: "",
            code: "",
        },
    });

    const getCognitoValues = (name?: string, shouldFormatCustom = true) => {
        console.log('LOGIN GETTING COGNITO VALUES:', name);
        console.log('LOGIN GETTING SECURE VALUES:', getSecureValues());
        console.log('LOGIN GETTING SECURE VALUES:', getSecureValues('password'), 'name === ""password"', name === 'password');
        if (name === 'password') return getSecureValues('password');
        if (name === 'code') return getSecureValues('code');

        const values = getValues(name as Path<CognitoFields> || undefined);
        if (shouldFormatCustom && typeof values === 'object') return formatCustom(values);
        return values;
    }

    const setCode = (code: string) => {
        setSecureValue('code', code);
    }

    const setUserType = (userType: string) => {
        form.setValue('custom.registration_type', userType);
    }

    const cognito = useCognito(getCognitoValues);
    const passwordField = ({ control:secureControl, name:'password', type:'password', placeholder:'Password' });
    const codeField = ({ control:secureControl, name:'code' });
    const useCognitoDateFieldWrapper = (name: Path<CognitoFields>) => useHookCompositeDateField({form, name});
    const useField = (name: string) => ({ control, name });
    const useCustomCognitoField = (name: Path<CognitoFields>) => useField('custom.'+name);

    const valueUpdated = (values: any) => {
        reset(values);
    }

    const values = watch();

    return (
        <AuthContext.Provider value={{ valueUpdated, setCode, setUserType, isLoading, watch, passwordField, codeField, useCognitoDateFieldWrapper, useCustomCognitoField, useField, ...cognito }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useHookCompositeDateField<T extends FieldValues>({form, name}:{form:UseFormReturn<T, any, any>, name:Path<T>}) {
    //return useCompositeDateFieldWrapper(useForm(), name);
    const {control:backingControl, watch, setValue:setBackingValue} = useForm({
        defaultValues: { day: "", month: "", year: "" },
    });

    const backing:any = watch(['day', 'month', 'year']);

    useEffect(() => {
        form.setValue(name, `${backing.day}/${backing.month}/${backing.year}` as any)
    }, [backing, form, name]);

    useEffect(() => {
        setBackingValue('day', `${backing.day}/${backing.month}/${backing.year}`)
        setBackingValue('month', `${backing.day}/${backing.month}/${backing.year}`)
        setBackingValue('year', `${backing.day}/${backing.month}/${backing.year}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.watch(name), backing.day, backing.month, backing.year, setBackingValue]);

    const useField = (name: string) => ({ control:backingControl, name });

    return { useField };
}

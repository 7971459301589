import {Button, PressEvent} from 'react-aria-components';
import {Icon} from "../../adornments/Icon/Icon";
import {IconStyle} from "../../../types";
import React from "react";

export function HubButton({name, flexWidth=true, leftIconStyle='Filled', rightIconStyle='Filled', type='button', size='md', buttonStyle='primary', leftIcon, rightIcon, text, badgeText, disabled, onClick}:
       {name?:string, flexWidth?:boolean, leftIconStyle?:IconStyle,
           rightIconStyle?:IconStyle, type?: "button" | "submit" | "reset" | undefined,
           buttonStyle?: "primary" | "secondary" | "tertiary" | "ghost", size?: "md" | "lg", leftIcon?:string,
           rightIcon?:string, text?:string, badgeText?:string, disabled?:boolean,
           onClick?: (e: PressEvent) => void}) {
    return (
        <>
            <Button name={name} type={type} onPress={onClick} className={`
            text-center
            justify-center
            ${flexWidth?'flex-1':'' }
        px-4 
        rounded-lg 
        flex 
        gap-2 
        items-center
        flex-shrink-0
        ${disabled?'opacity-40':''} 
        ${size==='md'?'h-10':'h-12'}
        ${buttonStyle==='primary'?'bg-primary text-white focus:border-white focus:outline-primary':''}
        ${buttonStyle==='secondary'?'bg-secondary text-grey-7D focus:border-secondary focus:outline-grey-7D':''}
        ${buttonStyle==='tertiary'?'bg-tertiary text-grey-7D focus:border-tertiary focus:outline-grey-7D':''}
        ${buttonStyle==='ghost'?'bg-transparent text-grey-7D focus:border-transparent focus:outline-grey-7D':''}
        focus:border focus:outline focus:outline-1
        `} isDisabled={disabled}>
                {leftIcon &&
                    <Icon iconStyle={leftIconStyle} name={leftIcon} style={buttonStyle} color={buttonStyle==='primary'?'white':'#7d7d7d'}></Icon>
                }
                {text}
                {rightIcon &&
                    <Icon iconStyle={rightIconStyle} name={rightIcon} badgeText={badgeText} style={buttonStyle} color={buttonStyle==='primary'?'white':'#7d7d7d'}></Icon>
                }
            </Button>
        </>
    )
}

import {Checkbox} from "react-aria-components";
import './Checkbox.css';
import React, {ReactNode} from "react";

export function HubCheckbox({value = false, children, onChange}: {value?: boolean, children?: ReactNode, onChange?: ((isSelected: boolean) => void) }) {
    return (
        <span className={'cursor-pointer'}>
            <Checkbox isSelected={value} onChange={onChange} >
            <div className="checkbox cursor-pointer">
                <svg viewBox="0 0 18 18" aria-hidden="true" className={'tick'}>
                    <polyline points="1 9 7 14 15 4" />
                </svg>
            </div>
                {children}
        </Checkbox>
        </span>
    )
}

import { Button, Dialog, PressEvent } from "react-aria-components";
import Img from '../../assets/images/login.svg';
import { HubButton } from "../../atoms/inputs/Button/Button";
import { Icon } from "../../atoms/adornments/Icon/Icon";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SignupContext } from "../../providers/Signup/SignupContext";
import { CognitoUser } from "../../hooks/aws/cognito/cognitoUser";
import { ToastContext } from "../../molecules/Toast/ToastProvider";
import { AuthContext } from "../../providers/Signup/AuthContext";
import {HookTextField} from "../../atoms/inputs/TextField/HookTextField";

export function Signup({type='learning', changeType, onChooseLogin, onClose}:{type?:string, changeType?: ()=>void, onChooseLogin?: () => void, onClose?: () => void}) {
    const baseInfo: CognitoUser = {};
    // @ts-ignore
    baseInfo["custom:registration_type"] = type;
    const cognito = useContext(AuthContext);
    const { handleSignupType } = useContext(SignupContext);
    const navigate = useNavigate();
    const typeField = cognito.useCustomCognitoField('registration_type');

    const {addSuccessToast, addErrorToast} = useContext(ToastContext);

    const onSignup = async (e: PressEvent) => {
        console.log("SIGNING UP");
        localStorage.setItem('userType', type);
        cognito.setUserType(type);

        if (cognito.registerCognitoUser) {
            try {
                await cognito.registerCognitoUser(e);
                handleSignupType(type);
                if (addSuccessToast) {
                    addSuccessToast('Your sign up is successful! Please check your email to confirm your account and login.');
                }
                onClose && onClose();
            }
            catch (e) {
                const {errors} = e as any;
                (errors as string[])?.forEach((error: string) => {
                    addErrorToast && addErrorToast(error);
                });
            }


        }
    }

    return (
        <Dialog>
            {({ close }) => (
                <div className={'flex gap-3 bg-white w-full rounded-lg'}>
                    <img src={Img} alt="" className={'w-[360px]'}/>
                    <section className={'p-6 flex flex-col w-[400px] gap-6 relative'}>
                        <div className={'flex flex-col gap-3 text-body-14'}>
                            <h2 className={'text-title-24'}>Sign up to start {type}</h2>
                            <p>By signing up or logging in, you agree to our
                                Terms of Service and Privacy Policy.</p>
                        </div>
                        <fieldset className={'flex flex-col gap-3'}>
                            <HookTextField {...cognito.useField('email')} placeholder={'E-mail'} Icon={<Icon name={'mail'}/>}/>
                            <HookTextField {...cognito.passwordField} placeholder={'Password'} type={'password'} description={'Password requirements'}
                                          Icon={<Icon name={'key-square'}/>}/>
                        </fieldset>
                        <div className={'flex'}>
                            <HubButton
                                text={`Sign up to ${type === 'teaching' ? 'teach' : 'learn'}`}
                                onClick={(e) => onSignup(e)}
                                type={'submit'}
                            />
                        </div>
                        {/*<div className={'flex flex-col gap-input'}>
                            <span className={'text-center'}>or continue with</span>
                            <span className={'flex justify-between gap-[17px]'}>
                                <HubButton style={'secondary'} leftIcon={'Google'} leftIconStyle={'Logos'}/>
                            </span>
                        </div>*/}
                        <span className={'flex h-10 justify-between absolute bottom-[20px] w-[350px]'}>
                            <Button onPress={onChooseLogin} className={'cursor-pointer'}>Login</Button>
                            <button className={'cursor-pointer'} onClick={changeType}>Become a {type==='teaching'?'student':'teacher'}</button>
                        </span>
                    </section>
                </div>
            )}
        </Dialog>
    )
}

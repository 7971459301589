import { TextField, Label as LabelElement, Input } from 'react-aria-components';
import { FieldError, Text, TextArea } from 'react-aria-components';
import React, {ReactNode, useEffect} from "react";
import { HubPopover } from "../../adornments/Popover/Popover";
import {Shimmer} from "../../Shimmer/Shimmer";

export type HubTextFieldProps = {
    labelText?: string;
    helpText?: string | ReactNode;
    multiline?: boolean;
    Icon?: ReactNode;
    ref?: any;
    error?: any;
    description?: string;
    success?: string;
    placeholder?: string;
    isLoading? : boolean;
    [key: string]: any;
}

export function HubTextField({ isReadOnly=false, type, value, onChange, onBlur, invalid, isLoading, labelText, helpText, multiline, Icon, ref, error, description, success, placeholder, ...props }: HubTextFieldProps) {
    useEffect(() => {
        console.log('placeholder:', placeholder);
    }, [placeholder]);

    return (
        <TextField validationBehavior="aria"
                   value={value}
                   onChange={onChange}
                   onBlur={onBlur}
                   isRequired
                   isReadOnly={isReadOnly}

            // Let React Hook Form handle validation instead of the browser.
                   isInvalid={invalid}>
            <div className={'flex flex-col gap-input text-body-14 font-semibold justify-end'} >
                {labelText && <LabelElement className={'text-left text-grey-41 font-semibold flex gap-2'}>
                    {labelText}
                    {helpText && <HubPopover helpText={helpText} />}
                </LabelElement>}
                <div className={'w-full relative'} >

                    {!multiline && <div className={`flex-col    flex  text-body-16 text-grey-7D`}
                                                {...props}
                                                 >
                        <Shimmer isLoading={isLoading??false}>
                            <div className={'relative'}>
                            {Icon && <span className={'absolute h-full flex items-center inset-x-2 w-6'}>
                        {Icon}
                    </span>}
                                <Input type={type} placeholder={placeholder} ref={ref} className={`w-full items-center outline-none border border-outline border-solid rounded-lg h-12 flex-shrink-0 py-3 px-4 ${Icon ? 'pl-12' : ''}`}/>
                        </div>
                        </Shimmer>
                        <div className={'h-6'}>
                            <FieldError className={'text-left text-error'} >
                                {error?.message}
                            </FieldError>
                            {description && <Text slot={'description'} className={'text-left text-success'}>{description}</Text>}
                            {success && <Text className={'text-left text-success'}>{success}</Text>}
                        </div>
                    </div>}
                    {multiline && <TextField {...props}
                                             validationBehavior="aria" >
                        <TextArea ref={ref}
                                  className={'border border-outline border-solid rounded-lg w-full pl-4 py-2 h-[100px] text-body-16'}
                        placeholder={placeholder}>

                        </TextArea>
                        <div className={'h-6'}>
                            <FieldError>{error?.message}</FieldError>
                            {description &&
                                <Text slot={'description'}
                                      className={'text-left text-success'}>{description}</Text>}
                            {success && <Text className={'text-left text-success'}>{success}</Text>}
                        </div>
                    </TextField>}
                </div>
            </div>
        </TextField>
    );
}

import React from "react";
import userPic from '../../assets/images/userpic.svg';

export function Review() {
    return (
        <div className={'flex flex-col gap-4'}>
            <div className={'flex gap-6'}>
                <img src={userPic} alt="" className={'h-[56px]'}/>
                <div className={'flex flex-col gap-4'}>
                    <h3>Diana B.</h3>
                    <span>May 13, 2023</span>
                </div>
            </div>
            <p>
                My first university degree was in linguistics. I understand how language works, and how our
                mouths make sounds, in a very deep way. Since 2021 I have tutored hundreds of students (on 2
                platforms), which has helped me discover what adult students need and develop effective methods
                and approaches to supporting those needs. Just for fun, in early 2023 I've also completed a
                TEFOL/TESL certificate in teaching English as a foreign language.
            </p>
            <p>
                My first university degree was in linguistics. I understand how language works, and how our mouths make sounds, in a very deep way. Since 2021 I have tutored hundreds of students (on 2 platforms), which has helped me discover what adult students need and develop effective methods and approaches to supporting those needs. Just for fun, in early 2023  of students (on 2 platforms), which has helped me discover what adult students need and develop effective methods and approaches to supporting those needs. Just for fun, in early 2023 I've also completed a TEFOL/TESL certificate in teaching English as a foreign language
            </p>
            <span>Show less</span>
        </div>
    )
}

import React, {useContext, useEffect} from "react";
import {FileUpload} from "../../atoms/inputs/FileUpload/FileUpload";
import {PageHeader} from "../../layout/PageHeader/PageHeader";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import {HubPopover} from "../../atoms/adornments/Popover/Popover";
import {useFormContext} from "react-hook-form";
import {HookUploadField} from "../../atoms/inputs/FileUpload/HookFileUpload";
import {AuthContext} from "../../providers/Signup/AuthContext";
import {UserSignupContext} from "../../providers/Signup/UserSignupContext";

const getPresignedUrl = async (file: any) => {
    const response = await fetch('https://api.hubapi.com/v1/s3/presigned-url', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: file.name,
            type: file.type
        })
    });
    const data = await response.json();
    return data.url;
}

export function Verification({type='learning', changeType, onChooseLogin}:{type?:string, changeType?: ()=>void, onChooseLogin?: () => void}) {
    const uploadToS3 = async (file: any) => {
        if (true) return;
        const url = await getPresignedUrl(file);
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type
            },
            body: file
        });
        if (response.ok) {
            console.log('File uploaded successfully');
        }
    }

    const [doc, setDoc] = React.useState(undefined as string|undefined);
    const {handleSubmit, getValues,  reset, control, watch, setValue, clearErrors, formState: { errors } } = useFormContext();
    const {watch:cognitoWatch} = useContext(AuthContext);
    const cognitoID = cognitoWatch && cognitoWatch('cognito:username');
    const verification = watch('verification');
    const values = watch();
    const {user} = useContext(UserSignupContext);

    useEffect(() => {
        if (user) {
            reset({...getValues(), ...user});
        }
    }, [user]);

    return (
        <>
                <PageHeader>
                    <h1>Verification</h1>
                    <span>Complete the fields below so we can verify your account.</span>
                </PageHeader>
                <section>
                    <h2>Upload your ID photo:</h2>
                    <span className={'flex gap-2'}>
                        Please provide proof of a valid identification document.
                        <HubPopover helpText={<>
                            <p>We accept the following forms of identification:</p>
                            <ul>
                                <li>Passport.</li>
                                <li>ID card.</li>
                                <li>Driving license.</li>
                            </ul>
                        </>} />
                    </span>
                    {doc && <img src={doc}/>}
                    {verification && <img src={verification}/>}
                    {!verification && <HookUploadField subBucket={'verification'} userID={cognitoID} name={'verification'}
                                      control={control} onFileRef={(file: any) => uploadToS3(file)}
                                      onUrlGenerated={setDoc}>
                        {/*uploadMessage={'File size should not exceed 10MB. Formats: JPG, PNG.'} uploadText={'Upload Video'}*/}
                        <PageHeader>
                            <div
                                className={'flex gap-4 border-secondary border-dashed border-2 flex flex-col items-center justify-center h-[240px] rounded rounded-[16px]'}>
                                <DynamicSvgRenderer name={'upload'} color={'rgba(125, 125, 125, 1)'} size={80}/>
                                <h2 className={'text-title-24'}>Upload picture</h2>
                                <p>File size should not exceed 10mb. Formats: JPG, PNG.</p>
                            </div>
                        </PageHeader>
                    </HookUploadField>}
                </section>
        </>
    )
}

import {
    Button,
    ListBoxItem,
    Label,
    ListBox,
    Popover,
    Select,
    SelectValue,
    FieldError,
    Text
} from 'react-aria-components';
import './MJDSelect.css'
import {Icon} from "../../adornments/Icon/Icon";
import React, {ReactNode} from "react";
import {Shimmer} from "../../Shimmer/Shimmer";

export type HubSelectFieldProps = {
    items?:string[],
    value?:any,
    onChange?:any,
    placeholderText?:string,
    labelText?:string,
    IconAdornment?:ReactNode,
    description?: string,
    success?: string
    isLoading?: boolean;
    error?: any;
    invalid?:any;
}

const defaultItems = ['Bulldog', 'Cat', 'Dog', 'Kangaroo', 'Panda', 'Snake'];

export function MJDSelect({isLoading, error, invalid, description, success, IconAdornment, labelText, placeholderText, value, onChange, items=defaultItems}: HubSelectFieldProps) {
    console.log('SELECT VALUE', value?value:'undefined');
    return (
        <div className={'flex-1 flex flex-col justify-end'}>
            <Select selectedKey={value} isInvalid={invalid} onSelectionChange={onChange} placeholder={placeholderText}>
                <Label>{labelText}</Label>
                <Shimmer isLoading={isLoading??false}>
                    <Button
                        className={'flex gap-2 border-2 rounded-lg px-2 py-3 h-12 items-center w-full border-outline border-[1px] text-grey-7D'}>
                        {IconAdornment}
                        <SelectValue className={`text-grey-7D ${value ? 'opacity-100' : 'opacity-80'}`}/>
                        <span aria-hidden="true"><Icon name={'down_arrow_small'} iconStyle={'Regular'}
                                                       color={'var(--grey-7D-color)'}></Icon></span>
                    </Button>
                </Shimmer>
                <Popover className={'h-[300px] overflow-auto w-[200px]'}>
                    <ListBox className={'bg-white flex justify-center flex-col'}>
                        {items.map((item, index) => <ListBoxItem className={'flex flex-col justify-center text-center'} key={item} id={item}>{item}</ListBoxItem>)}
                    </ListBox>
                </Popover>
                <div className={'h-6'}>
                    <FieldError className={'text-left text-error'}>{error?.message}</FieldError>
                    {description && <Text slot={'description'} className={'text-left text-success'}>Title</Text>}
                    {success && <Text className={'text-left text-success'}>Title</Text>}
                </div>
            </Select>
        </div>
    )
}

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Search} from "./pages/Search/Search";
import {ProfilePage} from "./pages/Profile/ProfilePage";
import React, {useContext} from "react";
import './App.css';
import {CheckoutForm} from "./organisms/Payments/Payments";
import {ToastContext, ToastProvider} from "./molecules/Toast/ToastProvider";
import {Signup} from "./layout/Signup/Signup";
import {AuthProvider} from "./providers/Signup/AuthContext";

function App() {

  return (


            <BrowserRouter>
                <AuthProvider>
                    <ToastProvider>
                        <><Routes>
                                <Route path="/profile" element={<ProfilePage />} />
                                <Route path="/signup/*" element={<Signup />} />
                                <Route path="*" element={<Search />} />
                                {/*<Route path="/checkout" element={<CheckoutForm />} />*/}
                            </Routes></>
                    </ToastProvider>
                </AuthProvider>
            </BrowserRouter>


  );
}

export default App;

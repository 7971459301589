import {Breadcrumb, Breadcrumbs, Link, Menu, MenuItem} from "react-aria-components";
import {HubBreadcrumb} from "../Breadcrumb/Breadcrumb";
import React from "react";

import Img from "../../assets/images/logo.svg";

export function NavHeader({type='breadcrumb', links}: {type?: 'breadcrumb' | 'nav' | 'blank', links?: {name:string, url:string, current?:true}[]}) {
    return (
        <>
            <div className={'w-full h-6 bg-bg fixed z-50'}></div>
            <div className={'fixed top-6 bg-white rounded-2xl flex px-10 bg-bg justify-between w-full h-[88px] items-center sticky z-10 box-border mt-6'}>
                <Link href={'/'}>
                    <img src={Img}/>
                </Link>
                {type === 'nav' &&
                    <>
                        <Menu onAction={alert} className={'flex gap-6 flex pl-[180px]'}>
                            <MenuItem id="open">Tutor list</MenuItem>
                            <MenuItem id="rename">Become a Tutor</MenuItem>
                        </Menu>
                        <img src={'https://placehold.co/56x56'}/>
                    </>
                }
                {type === 'breadcrumb' &&
                    <div className={'mx-auto'}>
                        <HubBreadcrumb links={links}/>
                    </div>
                }
            </div>
        </>
    )
}

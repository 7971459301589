import Img from '../../assets/images/tutor-in-a-hat.svg';
import {Icon} from "../../atoms/adornments/Icon/Icon";
import {TagGroup, TagList} from "react-aria-components";
import {TopTutor} from "../../molecules/TagGroup/TopTutor/TopTutor";
import {HubButton} from "../../atoms/inputs/Button/Button";
import React from "react";

export function Card() {
    return (
        <div className={'flex flex-col gap-4 shadow-lg p-6 rounded-2xl'}>
            <div className={'flex flex-col gap-4 flex-shrink-0'}>
                <div className={'flex flex-col gap-4 relative flex-shrink-0'}>
                    <span className={'absolute left-4 top-4'}>
                       <TagGroup>
                            <TagList>
                                <TopTutor />
                            </TagList>
                       </TagGroup>
                    </span>
                    <img src={Img} height={'192px'} width={'192px'} className={'rounded-2xl h-[320px] w-[320px] flex-0'} />
                    <span className={'absolute left-4 bottom-4'}>
                        <Icon name={'play-btn'} iconStyle={'Controls'}></Icon>
                    </span>
                </div>
            </div>
            <div className={'flex flex-col gap-4'}>
                <h2 className={'flex gap-badge text-title-24 items-center'}>Christina H. <Icon name={'British'} iconStyle={'Flags'} size={30}></Icon></h2>
                <dl className={'flex flex-col gap-input'}>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'tutors'}></Icon>
                        <dt>Subjects:</dt>
                        <dd>English</dd>
                    </div>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'translation'}></Icon>
                        <dt>Speaks:</dt>
                        <dd>
                            <dl className={'flex'}>
                                <div className={'flex gap-badge'}>
                                    <dt>English</dt>
                                    <dd>Native</dd>
                                    <span>,</span>
                                </div>
                                <div className={'flex gap-badge'}>
                                    <dt>Spanish</dt>
                                    <dd>B1</dd>
                                </div>
                            </dl>
                        </dd>
                    </div>
                </dl>
            </div>
            <span className={'flex gap-input items-center'}>
                <Icon name={'star'} color={'var(--grey-41-color)'}></Icon>
                <span className={'text-grey-41 text-title-20'}>4.5</span>
                <span className={'text-title-20'}>•</span>
                <span className={'text-title-20'}>122 lessons</span>
            </span>
            <hr/>
            <div className={'py-4 flex justify-between items-center'}>
                <span>
                    <span>$5.00</span>
                    <span> / class</span>
                </span>
                <span className={'flex gap-badge'}>
                    <HubButton text={'Book trial lesson'}></HubButton>
                </span>
            </div>
        </div>
    )
}

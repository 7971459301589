import React, { useContext, useEffect, useRef } from "react";
import { PersonalInfo } from "../../organisms/PersonalInfo/PersonalInfo";
import { PageHeader } from "../../layout/PageHeader/PageHeader";
import { HubButton } from "../../atoms/inputs/Button/Button";
import { Fieldset } from "../../layout/Fieldset/Fieldset";
import { DialogTrigger, Modal } from "react-aria-components";
import { Certification } from "../../organisms/Certification/Certification";
import { SignupContext } from "../../providers/Signup/SignupContext";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import { HubPopover } from "../../atoms/adornments/Popover/Popover";
import { s3UploadService } from "../../hooks/aws/useS3/s3UploadService";
import { useFormContext } from "react-hook-form";
import { HubCKEditor } from "../../atoms/inputs/CKEditor/CKEditor";
import { UserSignupContext } from "../../providers/Signup/UserSignupContext";
import {HookTextField} from "../../atoms/inputs/TextField/HookTextField";
import {AuthContext} from "../../providers/Signup/AuthContext";
import {HookSelectField} from "../../atoms/inputs/Select/HookSelectField";
import {daysInMonth, monthNamesOfYear, yearsSince} from "../../utils";
import {HookUploadField} from "../../atoms/inputs/FileUpload/HookFileUpload";
import {ToastContext} from "../../molecules/Toast/ToastProvider";
import Img from "../../assets/images/tutor-in-a-hat.svg";

export function ProfileEdit({index}:{index?:number}) {
    const {user} = useContext(UserSignupContext);
    const cognito = useContext(AuthContext);
    const { signupType, setSubmitDisabled } = useContext(SignupContext);
    let form = useFormContext();
    const { reset, control, setValue, getValues, watch, formState: {errors} } = form;
    const [showCertificate, setShowCertificate] = React.useState(false);
    const { isLoading } = useContext(UserSignupContext);
    const month = watch('cognitoAtts.birthdate.month');
    const year = watch('cognitoAtts.birthdate.year');
    const [videoUrl, setVideoUrl] = React.useState(undefined);
    const cognitoID = watch('ID');
    const video = watch('video');
    const [isLoaded, setIsLoaded] = React.useState(false);
    const handleLoaded = (e: any) => setIsLoaded(true);

    useEffect(() => setIsLoaded(false), [video]);
    const {addErrorToast} = useContext(ToastContext);

    const cognitoAtts = cognito.watch && cognito.watch();
    useEffect(() => {
        console.log('COGNITO ATTS:', cognitoAtts);
    }, [cognitoAtts]);

    useEffect(() => {
        if (user) {
            reset({...getValues(), ...user});
        }
    }, [user]);

    const handleError = (e: any) => {
        console.log('VIDEO ERROR', e.target.src);
        addErrorToast && addErrorToast(`The video could not be loaded. Please try again. Video src ${e.target.src}`);
        reset({picture: undefined});
    }

    return (
        <>
            <PageHeader>
                <h1>Let's get your account set up</h1>
                    <span>Tell us a few things about yourself.</span>
            </PageHeader>
            <section>
                        <PersonalInfo />
                        <hr/>

                        <Fieldset>
                            <span className={'col-span-12'}><HookTextField control={control} name={'cognitoAtts.name'}
                                                                          placeholder={'First Name'}
                                                                          labelText={'First Name'}
                                                                           rules={{ required: 'First Name is required' }}
                                                                           defaultValue={cognitoAtts?.name}
                            /></span>
                            <span className={'col-span-12'}><HookTextField control={control} name={'cognitoAtts.family_name'}
                                                                          placeholder={'Last Name'}
                                                                          labelText={'Last Name'}
                                                                           defaultValue={cognitoAtts?.family_name}
                                                                           rules={{ required: 'Last Name is required' }}/></span>
                            <span className={'col-span-4'}><HookTextField type={'number'}
                                                                          name={'cognitoAtts.birthdate.day'}
                                                                          control={control}
                                                                         placeholder={'Day'}
                                                                          defaultValue={cognitoAtts?.birthdate?.toString()?.split('-')[2]}
                                                                         labelText={'Date of birth'}
                                                                          rules={{required: 'Day is required',
                                                                              min: {
                                                                                  value: 1,
                                                                                  message: 'Day must be at least 1',
                                                                              },
                                                                              max: {
                                                                                  value: daysInMonth(month, year),
                                                                                  message: `This month doesn't have that many days.`,
                                                                              }}}
                            /></span>
                            <span
                                className={'col-span-4 flex'}><HookSelectField name={'cognitoAtts.birthdate.month'}
                                                                               control={control}
                                                                                placeholderText={'Month'}
                                                                               defaultValue={monthNamesOfYear[parseInt(cognitoAtts?.birthdate?.toString()?.split('-')[1])]}
                                                                               items={monthNamesOfYear}
                                                                               rules={{required: 'Month is required'}}
                                                                        /></span>
                            <span
                                className={'col-span-4 flex'}><HookSelectField name={'cognitoAtts.birthdate.year'}
                                                                               control={control}
                                                                         placeholderText={'Year'}
                                                                               defaultValue={cognitoAtts?.birthdate?.toString()?.split('-')[0]}
                                                                               rules={{required: 'Year is required'}}
                                                                               items={yearsSince(1930)}/></span>
                            <span className={'col-span-12'}><HookTextField control={control} name={'cognitoAtts.phone_number'}
                                                                          rules={{ required: `Phone number is required.`,  pattern: {
                                                                              value: /^[+][0-9]{12}$/, message: "Invalid phone number format, should be in form +XXXXXXXXXXXX"
                                                                          }}}
                                                                           defaultValue={cognitoAtts?.phone_number}
                                                                          placeholder={'Mobile Number'}
                                                                          labelText={'Mobile Number'}/></span>
                        </Fieldset>
                    </section>
            {signupType === 'teaching' && <>
                        <section>
                            <h2>About you:</h2>
                            <Fieldset>
                        <span className={'col-span-12'}><HookTextField control={control} name={'headline'}
                                                                        placeholder={'Write a brief, attention-grabbing headline about yourself.'}
                                                                      labelText={'Headline'}
                                                                       rules={{ required: 'Headline is required' }}
                                                                      helpText={
                                                                          <>
                                                                              <p>Create a short headline showcasing your
                                                                                  specialty, experience, and unique
                                                                                  approach to grab students'
                                                                                  attention.</p>
                                                                              <p>Example:<br/>
                                                                                  “Spanish Tutor with 5+ years
                                                                                  experience - making language learning
                                                                                  fun!”</p>
                                                                          </>
                                                                      }/></span>
                                <span className={'col-span-12'}>{/*<HubTextField {...teacherDetails.useField("description")}
                                                                              placeholder={'Write a description showcasing your qualifications, teaching style, characteristics, etc.'}
                                                                              labelText={'Description'}
                                                                              helpText={<>
                                                                                  <p>Accepted certificates can be:</p>
                                                                                  <p>Relevant to language teaching.
                                                                                      Degree level or higher in
                                                                                      language(s).
                                                                                      Proof of teaching
                                                                                      specialisation.</p>
                                                                              </>} multiline={true}/>*/}
                                    <HubCKEditor value={user?.description} control={control} name="description"
                                        placeholder="Write a description showcasing your qualifications, teaching style, characteristics, etc."
                                    />
                                    </span>
                            </Fieldset>
                            <h2>Certification:</h2>
                            <span>
                        <DialogTrigger>
                            <span>
                                <HubButton text={'Add certificate'} onClick={()=>setShowCertificate(true)}/>
                            </span>
                            <Modal isDismissable isOpen={showCertificate} onOpenChange={setShowCertificate} >
                                <div className={'bg-white rounded rounded-[16px]'}>
                                    <Certification user={user} control={control} getValues={getValues} onClose={()=>setShowCertificate(false)} />
                                </div>
                            </Modal>
                        </DialogTrigger>
                    </span>
                        </section>
                        <section>
                            <h2>Video introduction:</h2>
                            <span>Record a short video introducing yourself to give students an idea of who you are.</span>
                            <span className={'font-bold flex gap-2'}>Video introduction requirements
                            <HubPopover helpText={<>
                                <p>DO:</p>
                                <ul>
                                    <li>Stay visible throughout the video.</li>
                                    <li>Record horizontally (16:9 aspect ratio preferred).</li>
                                    <li>Use natural daylight or bright lighting.</li>
                                    <li>Speak clearly; avoid background noise.</li>
                                    <li>Keep video 1-3 minutes long.</li>
                                    <li>Showcase fluency in all taught languages.</li>
                                </ul>
                                <p>DO NOT:</p>
                                <ul>
                                    <li>Record vertically.</li>
                                    <li>Record in poorly lit settings.</li>
                                    <li>Share personal details.</li>
                                    <li>Advertise other services.</li>
                                    <li>Use copyrighted content without permission.</li>
                                </ul>
                            </>}/>
                        </span>
                            {(video || videoUrl) &&
                                <div className={'relative flex flex-grow-0 flex-col items-center justify-center'}>
                                    <video onCanPlay={handleLoaded} onError={handleError} src={video ?? videoUrl}
                                           controls autoPlay/>
                                    <span className={'absolute top-[0] right-[0]'} hidden={!isLoaded || !video}>
                    <DynamicSvgRenderer name={'tick'} color={'green'}/>
                </span>
                                </div>
                        }
                        {(!video) && <HookUploadField subBucket={`video`} userID={cognitoID}
                                                                         onFileUploaded={() => setSubmitDisabled(false)}
                                                                         onFileUpload={() => setSubmitDisabled(true)}
                                                                         onUrlGenerated={setVideoUrl}
                                                                         uploadService={new s3UploadService()}
                                                                         allowedFileTypes={['video/quicktime', 'video/mp4', 'video/x-msvideo']}
                                                   control={control} name={'video'}
                                                   rules={{required: 'Video is required'}}>
                        {/*uploadMessage={'File size should not exceed 10MB. Formats: JPG, PNG.'} uploadText={'Upload Video'}*/}
                        <PageHeader>
                            <div
                                className={'flex gap-4 border-secondary border-dashed border-2 flex flex-col items-center justify-center h-[240px] rounded rounded-[16px]'}>
                                <DynamicSvgRenderer name={'upload'} color={'rgba(125, 125, 125, 1)'} size={80}/>
                                <h2 className={'text-title-24'}>Upload your video</h2>
                                <p>File size should not exceed 10MB. Formats: MOV, AVI, MP4.</p>
                            </div>
                        </PageHeader>
                    </HookUploadField>}
                </section>
            </>}
        </>
    );
}

/**
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
// @ts-ignore
import { curry, defaultTo } from "ramda";

import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    AuthFlowType,
    GetUserCommand,
    UpdateUserAttributesCommand,
    ListGroupsCommand,
    SignUpCommand, ConfirmSignUpCommand

} from "@aws-sdk/client-cognito-identity-provider";
import {AuthService} from "../useS3/authService";

export class CognitoAuthService implements AuthService {
    public client;

    public clientId: string = '49i6o9bcb0noqs61pq6qh9nr9j';

    public AccessToken;

    public UserPoolId;

    constructor({AccessToken, storageMechanism}: {AccessToken?: any, storageMechanism?: "localStorage" | "cookie"} = {}) {
        if (storageMechanism) {
            this.storageMechanism = storageMechanism;
        }

        const DEFAULT_REGION = "eu-west-1";

        const orDefaultRegion = defaultTo(DEFAULT_REGION);

        const createClientForRegion = curry(
            (region: any, ClientConstructor: any) =>
                new ClientConstructor({ region: orDefaultRegion(region) })
        );

        const createClientForDefaultRegion = createClientForRegion(null);
        this.client = createClientForDefaultRegion(CognitoIdentityProviderClient);
        this.AccessToken = AccessToken;
        this.UserPoolId = 'eu-west-1_MMptAJ2A4';
    }

    readonly storageMechanism: 'localStorage' | 'cookie' | undefined;

    async getAuthToken() {
        if (this.storageMechanism === 'localStorage') {
            return localStorage.getItem('accessToken');
        } else {
            return document.cookie.replace(/(?:(?:^|.*;\s*)accessToken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        }
    }

    async signIn({ email, password }:{email: string, password: string}) {
        try {
            const command = new InitiateAuthCommand({
                AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
                AuthParameters: {
                    USERNAME: email,
                    PASSWORD: password,
                },
                ClientId: this.clientId,
            });

            const result = await this.client.send(command);

            if (result.AuthenticationResult) {
                return result.AuthenticationResult;
            } else {
                throw new Error('No access token in response');
            }
        } catch (e) {
            throw e;
        }
    }

    async signOut() {
        if (this.storageMechanism === 'localStorage') {
            localStorage.removeItem('accessToken');
        } else {
            document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970';
        }
    }

    /**
     * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
     * SPDX-License-Identifier: Apache-2.0
     */

    public confirmSignUp = ({ email, code }:{ email:string, code:string }) => {
        console.log('confirming signup');

        const command = new ConfirmSignUpCommand({
            ClientId: this.clientId,
            Username: email,
            ConfirmationCode: code,
        });

        return this.client.send(command);
    };

    /** snippet-start:[javascript.v3.cognito-idp.actions.SignUp] */
    public signUp = async (user:any, password: string) => {
        console.log({ user, password });
        try {
            const {email, ...userAttributes} = user;
            const attributes = Object.keys(userAttributes).map((key: any) => {
                return {Name: key, Value: userAttributes[key]};
            });
            const command = new SignUpCommand({
                ClientId: this.clientId,
                Username: email,
                Password: password,
                UserAttributes: attributes,
            });

            let response = await this.client.send(command);

            localStorage.setItem("user_sub", response.UserSub);

            if (response.UserConfirmed) {
                response = await this.signIn({ email, password });
            }

            return response;
        }
        catch (e:any) {
            console.log('ERROR: ', e.toString());
            const errors = [];
            if (e.toString().includes('User already exists')) {
                errors.push('User already exists');
            }
            if (e.toString().includes('User is disabled')) {
                errors.push('User is disabled');
            }
            if (e.toString().includes('User does not exist')) {
                errors.push('User does not exist');
            }
            if (e.toString().includes('Incorrect username or password.')) {
                errors.push('Incorrect username or password.');
            }
            if (e.toString().includes('User is not confirmed.')) {
                errors.push('User is not confirmed.');
            }
            if (e.toString().includes( 'User is already confirmed.')) {
                errors.push('User is already confirmed.');
            }
            if (e.toString().includes('Invalid verification code provided, please try again.')) {
                errors.push('Invalid verification code provided, please try again.');
            }
            if (e.toString().includes('Invalid password format.')) {
                errors.push('Invalid password format.');
            }
            if (e.toString().includes('Invalid phone number format.')) {
                errors.push('Invalid phone number format.');
            }
            if (e.toString().includes('Invalid email address format.')) {
                errors.push('Invalid email address format.');
            }
            if (e.toString().includes('An account with the given email already exists.')) {
                errors.push('An account with the given email already exists.');
            }
            if (e.toString().includes('An account with the given phone_number already exists.')) {
                errors.push('An account with the given phone_number already exists.');
            }
            if (e.toString().includes('Value at \'password\' failed to satisfy constraint')) {
                errors.push('Stronger password required');
            }
            if (e.toString().includes('Member must have length greater than or equal to 1')) {
                errors.push('Ensure all fields are filled in');
            }
            if (errors.length === 0) {
                if (e.toString().includes(':')) {
                    const errorArray = e.toString().split(':');
                    const [_first, ...errorMessage] = errorArray;
                    errors.push(errorMessage.join(': '));
                }
                else {
                    errors.push(e.toString());
                }
            }
            throw {errors};
        }
    };

    /** snippet-end:[javascript.v3.cognito-idp.actions.SignUp] */

    public update = async (attributes:any) => {
    const input = { // UpdateUserAttributesRequest
        UserAttributes: attributes,
        AccessToken: this.AccessToken, // required
    };
    const command = new UpdateUserAttributesCommand(input);
    const response = await this.client.send(command);

}

    public getUser = async () => {
    const AccessToken = this.AccessToken;
    const input = { // GetUserRequest
        AccessToken, // required
    };
    const command = new GetUserCommand(input);

    return await this.client.send(command);
};

    public getUserGroups = async (Limit?: number, NextToken?: string) => {
        const input = { // ListGroupsRequest
            UserPoolId: this.UserPoolId, // required
            Limit,
            NextToken,
        };
        const command = new ListGroupsCommand(input);
        const response = await this.client.send(command);
        return response;
    }
}


import Img from '../../../../assets/images/tutor-in-a-hat.svg';
import {Icon} from "../../../../atoms/adornments/Icon/Icon";
import React from "react";

export function GroupCard() {
    return (
        <div className={'flex gap-6 w-[800px]'}>
            <img src={Img} alt="" className={'h-[216px]'}/>
            <div className={'flex flex-col gap-input my-auto w-full'}>
                <span>Thu, 21:00 – 21:55</span>
                <h2>Speak with Confidence at Work</h2>
                <dl className={'flex flex-col gap-input'}>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'tutors'}></Icon>
                        <dt>Subjects:</dt>
                        <dd>English</dd>
                    </div>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'translation'}></Icon>
                        <dt>Speaks:</dt>
                        <dd>
                            <dl className={'flex'}>
                                <div className={'flex gap-badge'}>
                                    <dt>English</dt>
                                    <dd>Native</dd>
                                    <span>,</span>
                                </div>
                                <div className={'flex gap-badge'}>
                                    <dt>Spanish</dt>
                                    <dd>B1</dd>
                                </div>
                            </dl>
                        </dd>
                    </div>
                </dl>
                <span className={'flex justify-between'}>
                    <span className={'mt-1 text-title-18'}><span className={'font-semibold text-grey-41'}>$5.00</span> / class</span>
                    <span className={'mt-auto flex gap-badge'}>
                        <Icon name={'users-group'}></Icon>
                        6 spots left
                    </span>
                </span>
            </div>
        </div>
    )
}

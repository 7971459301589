import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import type { SearchResults } from 'algoliasearch-helper';
import type {
    CurrentRefinementsConnectorParamsRefinement,
    CurrentRefinementsRenderState,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import type { UiState } from 'instantsearch.js';
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch';

import { Panel } from './Panel';
import { useCloseDropdown } from './hooks/useCloseDropdown';
import { useLockedBody } from './hooks/useLockedBody';
import { capitalize, cx, getFirstChildPropValue } from './utils';

import './FacetDropdown.scss';

export type DropdownProps = PropsWithChildren<{
    buttonText?: string | ((options: DropdownButtonTextOptions) => string);
    classNames?: Partial<DropdownClassNames>;
    closeOnChange?: boolean | (() => boolean);
    badgeText?:string,
    isRange?:boolean
}>;

export type DropdownButtonTextOptions = {
    results: SearchResults;
    uiState: UiState;
    refinements: CurrentRefinementsConnectorParamsRefinement[];
};

export type DropdownClassNames = {
    root: string;
    button: string;
    buttonRefined: string;
    closeButton: string;
    mobileTitle: string;
};

type MiddlewareProps = Pick<DropdownProps, 'closeOnChange'> & {
    isOpened: boolean;
    close: () => void;
};

function getAttributeRefinements(
    attribute: string,
    items: CurrentRefinementsRenderState['items']
) {
    const item = items.find((item) => item.attribute === attribute);
    return item?.refinements || [];
}

function DropdownMiddleware({
                                isOpened,
                                closeOnChange,
                                close,
                            }: MiddlewareProps) {
    const { addMiddlewares } = useInstantSearch();

    useEffect(() =>
        addMiddlewares(() => ({
            onStateChange() {
                const shouldCloseOnChange =
                    closeOnChange === true ||
                    (typeof closeOnChange === 'function' && closeOnChange() === true);

                // Close the dropdown if it's opened and `closeOnChange` is true
                if (isOpened && shouldCloseOnChange) {
                    close();
                }
            },
        }))
    );

    return null;
}

export function FacetDropdown({
                                  children,
                                  buttonText,
                                  closeOnChange,
                                  classNames = {},
                                  badgeText,
                                  isRange=false,
                              }: DropdownProps) {
    const { results, uiState } = useInstantSearch();
    const { items } = useCurrentRefinements(
        {},
        { $$widgetType: 'cmty.facetDropdown' }
    );
    const [isOpened, setIsOpened] = useState(false);
    const panelRef = useRef(null);

    // Close the dropdown when click outside or press the Escape key
    const close = useCallback(() => setIsOpened(false), []);
    useCloseDropdown(panelRef, close, isOpened);

    // Prevent scrolling on mobile when the dropdown is opened
    const isMobile = false;
    useLockedBody(isOpened && isMobile);

    // Get the attribute(s) of the first child widget
    const attributeProp = getFirstChildPropValue(children, (props:any) =>
        'attributes' in props ? 'attributes' : 'attribute'
    );
    if (!attributeProp) {
        throw new Error(
            '<Dropdown> widget only supports InstantSearch widgets with an `attribute` or `attributes` prop.'
        );
    }

    // Get the refinements for the attribute
    const attribute =
        typeof attributeProp === 'string' ? attributeProp : attributeProp[0];
    const refinements = getAttributeRefinements(attribute, items);
    const isRefined = refinements.length > 0;
    const isDisabled = results.hits.length === 0;



    // Get the header button text
    let text;
    if (typeof buttonText === 'string') {
        text = buttonText;
    } else if (typeof buttonText === 'function') {
        text = buttonText({ results, uiState, refinements });
    } else if (typeof attribute === 'string') {
        text = isRefined
            ? `${capitalize(attribute)} (${refinements.length})`
            : capitalize(attribute);
    }

    const header = (
        <button
            type="button"
            className={cx(
                'flex border-solid border-[2px] items-center justify-between w-full h-full bg-white rounded-[8px] py-3 px-4',
                classNames.button,
                isRefined &&
                cx('ais-Dropdown-button--refined', classNames.buttonRefined),
                isDisabled && 'ais-Dropdown-button--disabled',
                badgeText && 'primary-one',
            )}
            disabled={isDisabled}
            onClick={() => setIsOpened((opened) => !opened)}
        >
            {text} {isRange?
                        (refinements && refinements.length > 1 && `${refinements[0].value} to ${refinements[1].value}`):
                        (refinements && refinements.length > 0 && refinements[0].value)}
            {badgeText? (<span className={'px-[7px] py-[5px] w-[60px] text-primary rounded-corners text-body-14 bg-bg-emphasis'}>{badgeText}</span>):
                (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#1B1F3B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>)}

        </button>
    );

    const footer = (
        <button
            className={cx(
                'ais-Dropdown-close ais-Dropdown-button',
                classNames.closeButton
            )}
            onClick={close}
        >
            Apply
        </button>
    );

    return (
        <Panel
            header={header}
            footer={footer}
            className={cx(
                'ais-Dropdown flex-grow text-center',
                isOpened && 'ais-Dropdown--opened',
                classNames.root
            )}
            ref={panelRef}
        >
            <DropdownMiddleware
                isOpened={isOpened}
                closeOnChange={closeOnChange}
                close={close}
            />
            <h2 className={cx('ais-Dropdown-mobileTitle', classNames.mobileTitle)}>
                {text}
            </h2>
            {children}
        </Panel>
    );
}

import { MouseEventHandler, useEffect, useState } from "react";
import { CognitoUser } from "./cognitoUser";
import { CognitoAuthService } from "./cognitoAuthService";
import { useLocalStorage } from "../../useLocalStorage";
import { PressEvent } from "react-aria-components";
import { jwtDecode } from 'jwt-decode';

export type CognitoAccessors = {
    isLoggedIn?: boolean;
    registerCognitoUser?: ((e: PressEvent) => (void | Promise<void>)) | undefined;
    login?: ((e: PressEvent) => (void | Promise<void>)) | undefined;
    logoutHandler?: MouseEventHandler;
    logout?: ()=>void;
    errors?: string[];
    accessTokenString?: string | null;
    userGroups?: any;
    id?: string;
    setId?: any;
    value?: any;
    confirmSignup?: ((e: PressEvent) => (void | Promise<void>)) | undefined;
};

function decodeToken(token:string) {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Failed to decode token', error);
    }
}

export function useCognito(getValues: (name?:string) => any) {
    const cognito = new CognitoAuthService({storageMechanism:'cookie'});
    let accessToken:string | null = null;
    cognito.getAuthToken().then((token) => accessToken = token);
    const userGroups = useLocalStorage('userGroups');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        console.log('IS LOGGED IN WITHIN HOOK:', isLoggedIn);
    }, [isLoggedIn]);

    useEffect(() => {
        console.log('CHECKING SESSION STATE:', sessionStorage.getItem('IdInfo'));
        // Mock of an authentication check
        if (sessionStorage.getItem('IdInfo') !== null) {
            // Assume some logic here that checks auth status
            setIsLoggedIn(true);  // Example of setting logged in status
        }
    }, []);

    const registerCognitoUser: ((e: PressEvent) => void) | undefined = async (_event: any) => {
        // event?.preventDefault();
        const user = getValues();
        console.log('REGISTERING USER:', user);
        console.log('PASSWORD:', getValues("password"));
        console.log('REGISTERING values:', user);
        const strippedUser = {email:user.email, "custom:registration_type":user["custom:registration_type"]}
        const result = await cognito.signUp(strippedUser, getValues("password"));

        /*if (result.hasErrors) {
            setErrors(result.errors);
        }*/
    }

    const login: ((e: PressEvent) => void) | undefined = async (_event: any) => {
        const email = getValues("email");
        const password = getValues("password");

        const result = await cognito.signIn({email: email??'', password:password});

        console.log('LOGIN RESULT:', result);

        const idFields = decodeToken(result.IdToken);
        const {aud, auth_time, email_verified, event_id, exp, iat, iss, jti, origin_jti, sub, token_use, ...idInfo} = idFields as any;

        console.log(idInfo);

        sessionStorage.removeItem('stages');

        sessionStorage.setItem('IdInfo', JSON.stringify(idInfo));

        sessionStorage.setItem('IdToken', result.IdToken);

        sessionStorage.setItem('accessToken', result.AccessToken);

        document.cookie = `accesstoken=${result.AccessToken}; path=/; domain=localhost; secure; SameSite=None";`;

        document.cookie = `refreshtoken=${result.RefreshToken}`;

        document.cookie = `tokentype=${result.TokenType}`;

        return {idInfo, accessToken:result.AccessToken};
    }

    const logoutHandler: MouseEventHandler = async (event?: any) => {
        event && event.preventDefault();
        logout();
    }

    const logout = async () => {
        sessionStorage.removeItem('IdInfo');
        setIsLoggedIn(false);
    }

    const confirmSignup: ((e: PressEvent) => void) | undefined = async (_event: any) => {
        const code = getValues('code');
        const email = getValues('email');
        console.log('CONFIRM values:', email, code);
        const result = await cognito.confirmSignUp({email: getValues('email'), code: getValues('code')});
    }

    return { logoutHandler, isLoggedIn, confirmSignup, registerCognitoUser, login, logout, accessTokenString: accessToken, userGroups } as CognitoAccessors;
}

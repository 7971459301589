import React, {forwardRef, ReactNode, useContext, useImperativeHandle} from "react";
import {Button, DropItem, DropZone, FieldError, FileDropItem, FileTrigger} from "react-aria-components";
import {ToastContext} from "../../../molecules/Toast/ToastProvider";
import {HookTriggerField} from "./HookFileUpload";

export interface FileUploadService {
    uploadMany(files: File[], uploadChunked?: boolean): void;
    uploadOne(file: File): void;
    uploadChunked(file: File): void;
}

const isValidated = (files: any[], allowedFileTypes = ['image/jpeg', 'image/png']) => {
    if (files.length > 1) {
        throw new Error('You can only upload one file at a time');
    }

    else {
        const file = files[0];

        console.log('file', file);
        console.log('allowed', allowedFileTypes);
        if (!allowedFileTypes.includes(file.type)) {
            throw new Error('The file type is not allowed');
        }

        if (file.size > 1000000000) {
            throw new Error('The file size is too large');
        }

        return true;
    }
}

const getPresignedUrl = async (file: any, subBucket?: string) => {
    const response = await fetch(`${process.env.REACT_APP_API??''}/api/s3-url`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `${sessionStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({
            name: file.name,
            mimeType: file.type,
            subBucket
        })
    });
    const data = await response.json();
    console.log('returning url', data.url);
    return data.url;
}

const uploadToS3 = async (file: File, userID:string, subBucket?: string) => {
    //if (true) return;
    const url = await getPresignedUrl(file, subBucket);
    console.log('url', url);
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type
        },
        body: file
    });
    if (response.ok) {
        console.log('File uploaded successfully');
        return `https://lhubfiles.s3.eu-west-1.amazonaws.com/${userID}/${subBucket}/${file.name}`;
    }
}

type SharedProps = {
    defaultValue?: string | File,
    control?:any,
    rules?:any,
    name?:string,
    onFileUploaded?:(filepath:string)=>void,
    onFileUpload?:()=>void,
    allowedFileTypes?: string[],
    subBucket?:string,
    uploadService?: any,
    children:ReactNode,
    allowsMultiple?: boolean,
    onUrlGenerated?:Function,
    onFileRef?:Function,
    onChange?:Function,
    invalid?:boolean,
    error?:any,
    saveMode?:'urlOnly'|'fileOnly'|'both',
    ref?: any,
    userID:string;
};
export type HubTriggerFieldProps = SharedProps & { includeTrigger?: boolean, onSelect?:Function};
export type HubUploadFieldProps = SharedProps & { requiresHookControl?: boolean};

export const FileUploadTrigger = ({userID, saveMode='urlOnly', ref, defaultValue,  error,invalid, control, rules, name, onChange, onFileUploaded, onFileUpload, subBucket,  requiresHookControl=true, uploadService, allowedFileTypes, children, allowsMultiple=false, onUrlGenerated, onFileRef}:
                                                 HubUploadFieldProps) => {

    let [files, setFiles] = React.useState(defaultValue as any|any[]|undefined);

    const {addErrorToast} = useContext(ToastContext);

    const handleSelect = async (e: any) => {
        console.log('SELECTED', e);
        if (e) {
            let files: any[] = Array.from(e);
            console.log('SELECTED FILES', files);
            try {
                if (isValidated(files, allowedFileTypes)) {
                    const fileArray: File[] = files;
                    /*for (let file of files) {
                        fileArray.push(await file.getFile());
                    }*/
                    setFiles(fileArray);
                    if (onUrlGenerated) {
                        //const url = await readFileAsDataURL(files[0]);
                        //console.log((files[0] as any[])[0]);
                        const url = URL.createObjectURL((files[0]) as File);
                        console.log('FILE URL GENERATED', url);
                        onUrlGenerated(url);
                    }
                    if (onFileRef) {
                        onFileRef(await files[0]);
                    }
                    const file = await files[0];
                    if (onFileUpload) {
                        onFileUpload();
                    }
                    const url = await uploadToS3(file, userID, subBucket);
                    if (onFileUploaded) {
                        onFileUploaded(file.name);
                    }
                    console.log('SELECTED FIRST FILE', file);
                    console.log('File Change', onChange);
                    if (onChange) {
                        console.log('File Changing', saveMode, url, file);
                        const {type, name, size} = file;
                        switch (saveMode) {
                            case 'urlOnly':
                                onChange(url);
                                break;
                            case 'fileOnly':
                                onChange(file);
                                break;
                            case 'both':
                                console.log('SAVING BOTH: ' + url + ' ' + type);
                                onChange({url, type, name, size});
                                break;
                        }
                    }
                }
            }
            catch (e:any) {
                addErrorToast && addErrorToast(e.message);
            }
        }
    }

    useImperativeHandle(ref, () => ({
        triggerUpload() {
            if (files && uploadService) {
                uploadService.uploadMany(files);
            }
        }
    }));

    return (
        <div>
            <FileTrigger  ref={ref} allowsMultiple={allowsMultiple} onSelect={handleSelect} >
                {/*<HubButton flexWidth={false} text={'Choose photo'} leftIcon={'image-gallery'} />*/}
                {children}
            </FileTrigger>
            {error?.message && <span className={'text-left text-error'}>{error?.message}</span>}
        </div>
    )
}

export const FileUpload = ({userID, saveMode='urlOnly', ref, error, defaultValue, onFileUpload, invalid, onChange, control, rules, name, subBucket,  onFileUploaded, uploadService, allowedFileTypes, children, allowsMultiple=false, includeTrigger=true, onFileRef, onUrlGenerated}:
                                          HubTriggerFieldProps) => {
    const {addErrorToast} = useContext(ToastContext);

    let [files, setFiles] = React.useState([] as any|any[]|undefined);

    const handleDrop = async (e: any) => {
        console.log('DROPPED', e);
        let files = e.items.filter((file:DropItem) =>
            file.kind === 'file'
        ) as FileDropItem[];
        try {
            if (isValidated(files, allowedFileTypes)) {
                const fileArray: File[] = [];
                for (let file of files) {
                    fileArray.push(await file.getFile());
                }
                setFiles(fileArray);
                if (onUrlGenerated) {
                    const url = URL.createObjectURL((await files[0].getFile()) as File);
                    onUrlGenerated(url);
                    /*const url = await readFileAsDataURL(files[0]);
                    onUrlGenerated(url);*/
                }
                if (onFileRef) {
                    onFileRef(await files[0].getFile());
                }
                const file = await files[0].getFile();
                if (onFileUpload) {
                    onFileUpload();
                }
                const url = await uploadToS3(file, userID, subBucket);
                if (onFileUploaded) {
                    onFileUploaded(file.name);
                }
                if (onChange) {
                    switch (saveMode) {
                        case 'urlOnly':
                            onChange(url);
                            break;
                        case 'fileOnly':
                            onChange(file);
                            break;
                        case 'both':
                            console.log('SAVING BOTH: ' + url + ' ' + JSON.stringify(file));
                            onChange({url, ...file});
                            break;
                    }
                }
            }
        }
        catch (e:any) {
            addErrorToast && addErrorToast(e.message);
        }
    }

    useImperativeHandle(ref, () => ({
        logMessage(msg: string) {
            console.log(`message from FileUpload: ${msg}`);
        },
        triggerUpload() {
            if (files && uploadService) {
                uploadService.uploadMany(files);
            }
        }
    }));


    return (
        <div>
            <DropZone ref={ref} onDrop={(e: any) => handleDrop(e)}>
                {includeTrigger?
                    <FileUploadTrigger userID={userID} subBucket={subBucket} saveMode={saveMode} onChange={onChange} onUrlGenerated={onUrlGenerated} allowedFileTypes={allowedFileTypes} allowsMultiple={allowsMultiple} onFileRef={onFileRef}>
                        <Button className={'w-full'}>
                            {children}
                        </Button>
                    </FileUploadTrigger>
                    :
                    children
                }
            </DropZone>
            {error?.message && <span className={'text-left text-error'}>{error?.message}</span>}
        </div>
    )
}

import {Icon} from "../../../atoms/adornments/Icon/Icon";
import {Tag} from "react-aria-components";
import React from "react";

export function TopTutor() {
    return (
        <Tag className={'items-center flex rounded-lg border bg-grey-2F bg-opacity-30 text-white px-2 gap-badge text-body-14 font-semibold'}>
            <Icon name={'crown'} color={'white'} size={16}></Icon>
            TOP TUTOR
        </Tag>
    )
}

import {Menu, MenuItem} from "react-aria-components";
import {Icon} from "../../atoms/adornments/Icon/Icon";
import React, {useContext} from "react";
import {useCognito} from "../../hooks/aws/cognito/useCognito";
import {AuthContext} from "../../providers/Signup/AuthContext";

export function Sidebar() {
    const {logoutHandler, isLoggedIn} = useContext(AuthContext);

    return (
        <aside className={'h-full'}>
            <Menu className={'flex gap-input flex-col h-full text-grey-mid'}>

                {isLoggedIn && <MenuItem id="dashboard" className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input flex items-center outline-0 border-solid border-primary hover:bg-divider'}><Icon name={'home-roof'} color={'var(--grey-7D-color)'} iconStyle={'Regular'}></Icon>Home</MenuItem>}
                    <MenuItem id="tutors" className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input flex items-center outline-0 border-solid border-primary hover:bg-divider'}><Icon name={'tutors'} color={'var(--grey-7D-color)'} iconStyle={'Regular'}></Icon>Tutors</MenuItem>
                    <MenuItem id="group-sessions" className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input flex items-center outline-0 border-solid border-primary hover:bg-divider'}><Icon iconStyle={'Regular'} color={'var(--grey-7D-color)'} name={'group_sessions'}></Icon>Group Lessons</MenuItem>
                {isLoggedIn && <><MenuItem id="chat" className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input flex items-center outline-0 border-solid border-primary hover:bg-divider'}><Icon iconStyle={'Regular'} name={'chat'} color={'var(--grey-7D-color)'}></Icon>Messages</MenuItem>
                    <MenuItem id="payments" className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input flex items-center outline-0 border-solid border-primary hover:bg-divider'}><Icon iconStyle={'Regular'} color={'var(--grey-7D-color)'} name={'payments'}></Icon>Payments</MenuItem>
                    <MenuItem id="log-out"
                              className={'cursor-pointer h-12 pl-4 hover:pl-3 gap-input mt-auto flex items-center outline-0 hover:border-l-4 border-solid border-primary mt-auto translate-y-[-50px]  hover:bg-divider'}>
                        <button className={'flex gap-input'} onClick={logoutHandler}><Icon iconStyle={'Regular'}
                                                                                           color={'var(--grey-7D-color)'}
                                                                                           name={'log_out'}></Icon>Log out
                        </button>
                    </MenuItem>
                </>}
            </Menu>
        </aside>
    )
}

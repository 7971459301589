import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {useCognito} from "../../hooks/aws/cognito/useCognito";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";

export function Preferences({index}:{index?:number}) {
    const cognito = useCognito({} as any);

    const { control, getValues, formState: { errors }} = useFormContext();

    const userInfoString = sessionStorage.getItem('IdInfo');
    const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
    console.log('USER INFO:', userInfo);

    return (
        <>
                <section>
                    <h2 className={'text-title-24 '}>Your time zone:</h2>
                    <span className={'col-span-12'}><Controller
                        control={control}
                        name={`timezone`}
                        defaultValue={userInfo['custom:timezone']}
                        render={({
                                     field: {name, value, onChange, onBlur, ref},
                                     fieldState: {invalid, error}
                                 }: any) => (<MJDSelect items={['UTC']} onChange={onChange} value={value} />
                        )} /></span>
                </section>
                <section>
                    <h2 className={'text-title-24 '}>Your language:</h2>
                    <span className={'col-span-12'}><Controller
                        control={control}
                        name={`language`}
                        defaultValue={userInfo['custom:language']}
                        render={({
                                     field: {name, value, onChange, onBlur, ref},
                                     fieldState: {invalid, error}
                                 }: any) => (<MJDSelect items={['English']} onChange={onChange} value={value} />
                        )} /></span>
                </section>
                <section>
                    <h2 className={'text-title-24 '}>Preferred Currency:</h2>
                    <span className={'col-span-12'}><Controller
                        control={control}
                        name={`currency`}
                        defaultValue={userInfo['custom:currency']}
                        render={({
                                     field: {name, value, onChange, onBlur, ref},
                                     fieldState: {invalid, error}
                                 }: any) => (<MJDSelect items={['£']} onChange={onChange} value={value} />
                        )} /></span>
                </section>
        </>
    )
}

import React, {ReactNode} from "react";
import {Button, Dialog, DialogTrigger, OverlayArrow, Popover} from "react-aria-components";
import DynamicSvgRenderer from "../../../hooks/useDynamicSVG/useDynamicSVG2";

export function HubPopover({helpText}: {helpText?:string | ReactNode}) {
    return (
        <DialogTrigger>
            <Button>
                <DynamicSvgRenderer name={'info-square'} color={'rgb(230 230 230)'}/>
            </Button>
            <Popover>
                <OverlayArrow>
                    {/*<svg width={12} height={12} viewBox="0 0 12 12">
                        <path d="M0 0 L6 6 L12 0"/>
                    </svg>*/}
                </OverlayArrow>
                <Dialog className={'rounded-corners'}>
                    <div className="flex-col">
                        {helpText}
                    </div>
                </Dialog>
            </Popover>
        </DialogTrigger>
    );
}

// @ts-ignore
import type {AriaToastProps} from '@react-aria/toast';
// @ts-ignore
import {useToast} from '@react-aria/toast';

// Reuse the Button from your component library. See below for details.
// @ts-ignore
import {ToastState} from "@react-stately/toast";
import React from "react";
import {Button} from "react-aria-components";
import './Toast.scss';
import {Alert} from "../../organisms/Alert/Alert";

interface ToastProps<T> extends AriaToastProps<T> {
    state: ToastState<T>
    toast: any
}

export function Toast<T extends React.ReactNode>({state, ...props}: ToastProps<T>) {
    let ref = React.useRef(null);
    let {toastProps, titleProps, closeButtonProps} = useToast(props, state, ref);

    return (
        <div {...toastProps} ref={ref} className="toast absolute bottom-[0px] relative">
            <Alert status={props.toast.content.level} subtitle={props.toast.content.message} />
            <Button {...closeButtonProps} className={'absolute top-[30px] right-[30px]'}>x</Button>
        </div>
    );
}

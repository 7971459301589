import {Profile} from "../../organisms/Profile/Profile";
import {Feature} from "../../molecules/Feature/Feature";
import React from "react";
import Img from "../../assets/images/logo.svg";
import {Sidebar} from "../../organisms/Sidebar/Sidebar";
import {Booking} from "../../organisms/Booking/Booking";
import {Schedule} from "../../organisms/Schedule/Schedule";
import {Reviews} from "../../layout/Reviews/Reviews";
import {GroupCard} from "../../organisms/Cards/Small/Group/GroupCard";
import {Card} from "../../organisms/Cards/Card";
import {Footer} from "../../organisms/Footer/Footer";
import {Link} from "react-router-dom";


export function ProfilePage() {
    return (
        <>
            <div>
                <div className={'flex gap-8'}>
                    <div className={'bg-white w-[232px] pt-8'}>
                        <div className={'bg-white w-[232px] sticky top-8 h-[100vh] flex flex-col gap-8'}>
                            <Link to={'/'}>
                                <img src={Img}
                                    className={'rounded-2xl w-[108px] flex-0 ml-4'}/>
                            </Link>
                        <Sidebar/>
                    </div>
                </div>
                    <div className={'flex flex-col gap-8'}>
                        <div className={'rounded-8 rounded'}>
                            <Profile/>
                        </div>
                        <div className={'flex gap-6'}>
                            <div className={'bg-white w-[200px] p-4 flex-grow rounded-[16px]'}>
                                <Feature title={'4.9/5'} description={'31 user ratings'} icon={'star'}/>
                            </div>
                            <div className={'bg-white w-[200px] p-4 flex-grow rounded-[16px]'}>
                                <Feature title={'82'} description={'Number of lessons'} icon={'tutors'}/>
                            </div>
                            <div className={'bg-white w-[200px] p-4 flex-grow rounded-[16px]'}>
                                <Feature title={'100%'} description={'Success rate'} icon={'award-check'}/>
                            </div>
                        </div>
                        <h2>Tutor Schedule:</h2>
                        <div className={'rounded-[16px]'}>
                            <Schedule/>
                        </div>
                        <h2>Student reviews (31):</h2>
                        <div className={'rounded-[16px] bg-white'}>
                            <Reviews/>
                        </div>
                        <h2>Group lessons with Christina:</h2>
                        <div className={'bg-white w-fit pr-6 rounded-[16px] bg-white'}>
                            <GroupCard/>
                        </div>
                        <span className={'text-primary mx-auto'}>Show more</span>
                        <h2>Other tutors you might like</h2>
                        <div className={'flex gap-4'}>
                            <div className={'bg-white rounded-[16px]'}>
                                <Card/>
                            </div>
                            <div className={'bg-white rounded-[16px]'}>
                                <Card/>
                            </div>
                            <div className={'bg-white rounded-[16px]'}>
                                <Card/>
                            </div>
                            <div className={'bg-white rounded-[16px]'}>
                                <Card/>
                            </div>
                            <div className={'bg-white rounded-[16px]'}>
                                <Card/>
                            </div>
                        </div>
                    </div>
                    <div className={''}>
                        <Booking/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

import {Icon} from "../../atoms/adornments/Icon/Icon";
import React from "react";

export function Feature({title, description, icon}: {title: string, description: string, icon: string}) {
    return (
        <article className={'flex-col flex gap-input'}>
            <div className={'flex justify-between'}>
                <h2 className={'text-title-32 font-bold'}>{title}</h2>
                <Icon name={icon} size={40}></Icon>
            </div>
            <span>{description}</span>
        </article>
    )
}

import React, {ReactNode} from "react";

export function Fieldset({children}: {children: ReactNode}) {
    return (
        <fieldset className={'grid grid-cols-12 gap-x-4 gap-y-6'}>
            {children}
        </fieldset>
    )

}

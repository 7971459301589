import {Button, Dialog, DialogTrigger, Link, Modal, Section, TagGroup, TagList} from "react-aria-components";
import {Icon} from "../../../atoms/adornments/Icon/Icon";
import {TopTutor} from "../../../molecules/TagGroup/TopTutor/TopTutor";
import {HubButton} from "../../../atoms/inputs/Button/Button";

import Img from '../../../assets/images/tutor-in-a-hat.svg';
import React from "react";
import {Article} from "../../../layout/Article/Article";
import {HookTextField} from "../../../atoms/inputs/TextField/HookTextField";
import {CheckoutForm} from "../../Payments/Payments";

export function IndividualCard({hit: tutor}: {hit: any}) {
    console.log("tutor: ", tutor);

    return (
        <div className={'flex gap-6 p-6 shadow-lg rounded-[16px] bg-white hover:scale-101 cursor-pointer'}>
            <div className={'flex flex-col gap-4 flex-shrink-0'}>
                <div className={'flex flex-col gap-4 relative flex-shrink-0'}>
                    <span className={'absolute left-4 top-4'}>
                       <TagGroup>
                            <TagList>
                                <TopTutor />
                            </TagList>
                       </TagGroup>
                    </span>
                    <img src={tutor.picture??Img} height={'192px'} width={'192px'} className={'rounded-2xl h-[192px] w-[192px] flex-0'} />
                    <span className={'absolute left-4 bottom-4'}>
                        <Icon name={'play-btn'} iconStyle={'Controls'}></Icon>
                    </span>
                </div>
                <span className={'flex gap-input mx-auto'}>
                    <Icon name={'star'}></Icon>
                    <span>{tutor.stars / 10}</span>
                    <span>•</span>
                    <span>{tutor.numLessons} lessons</span>
                </span>
            </div>
            <div className={'flex flex-col gap-input ml-2 flex-shrink'}>
                <h2 className={'flex gap-badge text-title-24 items-center'}>{tutor.firstname} {tutor.lastname}<Icon name={tutor.language.replace('English', "British")} iconStyle={'Flags'} size={30}></Icon></h2>
                <dl className={'flex flex-col gap-input'}>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'tutors'}></Icon>
                        <dt>Subjects:</dt>
                        <dd>{tutor.language}</dd>
                    </div>
                    <div className={'flex gap-badge flex'}>
                        <Icon name={'translation'}></Icon>
                        <dt>Speaks:</dt>
                        <dd>
                            <dl className={'flex'}>
                                <div className={'flex gap-badge'}>
                                    <dt>{tutor.language}</dt>
                                    <dd>Native</dd>
                                    <span>,</span>
                                </div>
                                <div className={'flex gap-badge'}>
                                    <dt>{tutor.language2}</dt>
                                    <dd>{tutor.language2level}</dd>
                                </div>
                            </dl>
                        </dd>
                    </div>
                </dl>
                <div className={'flex flex-col gap-badge'}>
                    <h3>{tutor.bio1}</h3>
                    <p dangerouslySetInnerHTML={{__html: tutor.bio2}}>
                    </p>
                    <Link className={'text-primary'}>Read more</Link>
                </div>
            </div>
            <div className={'flex flex-col justify-between flex-shrink-0 w-min-[216px] ml-auto'}>
                <span className={'flex justify-end gap-6'}>
                    <Icon name={'arrow-share'}></Icon>
                    <Icon name={'heart'}></Icon>
                </span>
                <div className={'flex flex-col gap-4'}>
                    <span className={'flex justify-end'}>
                        <span>${tutor.price}.00</span>
                        <span> / 30 - minutes</span>
                    </span>
                    <span className={'flex gap-4 justify-end'}>
                        <HubButton leftIcon={'chat'} buttonStyle={'secondary'} flexWidth={false} />
                        <DialogTrigger>
                           <HubButton text={'Book trial lesson'} buttonStyle={'primary'} />
                            <Modal isDismissable={true} className={'overflow-hidden'}>
                                <Dialog >
                                    {({ close }) => (
                                        <div className={'flex gap-[32px] bg-white w-full rounded-lg m-8'}>
                                            <CheckoutForm closeModal={close} />
                                        </div>
                                    )}
                                </Dialog>
                            </Modal>
                        </DialogTrigger>
                    </span>
                </div>
            </div>
        </div>
    )
}

import Img from '../../assets/images/tutor-in-a-hat.svg';
import { HubButton } from "../../atoms/inputs/Button/Button";
import React, {useContext, useEffect} from "react";
import { HubPopover } from "../../atoms/adornments/Popover/Popover";
import { useFormContext } from "react-hook-form";
import {HookTriggerField} from "../../atoms/inputs/FileUpload/HookFileUpload";
import {ToastContext} from "../../molecules/Toast/ToastProvider";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import {AuthContext} from "../../providers/Signup/AuthContext";

export function PersonalInfo({onImageSelected}:{onImageSelected?:Function}) {
    const userInfoString = sessionStorage.getItem('IdInfo');
    const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
    const {control, watch, reset, getValues} = useFormContext();
    const [imageUrl, setImageUrl] = React.useState(userInfo.picture);
    const savedImageUrl = watch('picture');
    const {addErrorToast} = useContext(ToastContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const handleLoaded = (e: any) => setIsLoaded(true);
    useEffect(() => setIsLoaded(false), [savedImageUrl]);

    const {watch:cognitoWatch} = useContext(AuthContext);
    const cognitoID = cognitoWatch && cognitoWatch('cognito:username');

    const resetImageUrl = () => {
        const currentValues = getValues();
        setImageUrl(undefined);
        reset({...currentValues, picture: undefined});
    }

    const handleError = (e: any) => {
        console.log('PICTURE ERROR', e.target.src);
        addErrorToast && addErrorToast(`The image could not be loaded. Please try again. Image src ${e.target.src}`);
        reset({picture: undefined});
    }

    const noSpaces = (value:any) => !/%20/.test(value) || 'No spaces are allowed';

    return (
        <div className={'flex gap-10'}>
            <div className={'relative flex flex-grow-0 flex-col items-center justify-center'}>
                <img onError={handleError} onLoad={handleLoaded} src={savedImageUrl ?? imageUrl ?? Img} alt=""
                     className={'w-[192px] rounded-[16px]'}/>
                <span className={'absolute top-[0] right-[0]'} hidden={!isLoaded || !savedImageUrl}>
                    <DynamicSvgRenderer name={'tick'} color={'green'} />
                </span>
            </div>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-input'}>
                    <h2 className={'flex gap-2 items-center'}>
                        Upload profile picture
                        <HubPopover
                            helpText={
                                <>
                                    <ul>
                                        <li>Face and eyes fully visible (religious exceptions apply)</li>
                                        <li>Photo centered and upright.</li>
                                        <li>Colour photo with high resolution (no filters).</li>
                                        <li>Neutral lighting and background.</li>
                                        <li>Smile and look into the camera.</li>
                                    </ul>
                                </>
                            }
                        />
                    </h2>
                    <p>We recommend using a square image of 1000x1000px.
                        File size should not exceed 10MB. Formats: JPG, PNG.</p>
                </div>
                <span className={'flex gap-4'}>
                    <HookTriggerField userID={cognitoID} onFileRef={onImageSelected} allowedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
                                       allowsMultiple={false} onUrlGenerated={setImageUrl} subBucket={`picture`} control={control}
                                      rules={{required: 'Picture is required', validate: noSpaces}}
                                        name={'picture'} defaultValue={userInfo.picture} >
                        <HubButton flexWidth={false} text={'Choose photo'} leftIcon={'image-gallery'}/>
                    </HookTriggerField>

                    <HubButton flexWidth={false} text={'Remove photo'} buttonStyle={'secondary'}
                               onClick={() => resetImageUrl()}/>
                </span>
            </div>
        </div>
    )
}

import {Icon} from "../../atoms/adornments/Icon/Icon";
import {HubButton} from "../../atoms/inputs/Button/Button";
import {Button, Dialog, DialogTrigger, Modal} from "react-aria-components";
import React from "react";

export function Alert({status, subtitle}: {status: 'success' | 'error' | 'warning' | 'info', subtitle?: string}) {
    return (

                <Dialog className={'flex flex-col gap-4 shadow relative rounded-lg shadow-2xl py-4 px-6'} style={{width:'334px'}}>
                    {({ close }) => (

            <>
                {/*<div className={'absolute right-6 top-4'}>
                    <Icon name={'cross'} color={'var(--main-bg-color)'} size={18} iconStyle={'Regular'}></Icon>
                </div>*/}
                <div className={`h-10 border 
                ${status === 'error'?'border-error bg-error':''}
                ${status === 'info'?'border-primary bg-primary':''} 
                ${status === 'success'?'border-success bg-success':''} 
                bg-opacity-10 flex items-center justify-center w-10 rounded-lg`}>
                    {status === 'error' && <Icon name={'cross-square'} color={'var(--error-color)'} size={18}></Icon>}
                    {status === 'info' && <Icon name={'info-square'} color={'var(--primary-color)'} size={18}></Icon>}
                    {status === 'success' && <Icon name={'info-square'} color={'var(--success-color)'} size={18}></Icon>}
                </div>
                <div className={'gap-2 text-left flex flex-col'}>
                    <h2 className={'text-header-18 font-semibold text-grey-41'}>
                        {status === 'error' && 'Error'}
                        {status === 'info' && 'Info'}
                        {status === 'success' && 'Success'}
                    </h2>
                    <span className={'text-body-14 font-medium text-grey-7D'}>{subtitle}</span>
                </div>
                <div className={'flex text-center'}>
                    <HubButton text={'Label'} buttonStyle={'secondary'}/>
                    <HubButton text={'Label'} buttonStyle={'ghost'} />
                </div>
            </>

            )}
            </Dialog>

    )
}

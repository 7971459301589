// @ts-ignore
import {useToastState} from '@react-stately/toast';
import {ToastRegion} from './ToastRegion';
import React, {createContext, useEffect, useRef} from 'react';

type AddMessage = (message: string) => void;

export const ToastContext = createContext({ addErrorToast: undefined, addInfoToast: undefined, addSuccessToast: undefined} as {addErrorToast?:AddMessage, addInfoToast?:AddMessage, addSuccessToast?:AddMessage});

export function ToastProvider({ children, ...props }: {children: any}) {
    const [toast, setToast] = React.useState(undefined as any);

    let toastState = useToastState({
        maxVisibleToasts: 5,
        hasExitAnimation: true
    });

    const toastStateRef = useRef(toastState);
    useEffect(() => {
        toastStateRef.current = toastState;
    }, [toastState]);

    const addErrorToast = (message: string) => {
        const key = toastState.add({message, level: 'error'});
        setTimeout(() => {
            toastState.close(key);
            toastState.remove(key);
        }, 5000);
    }

    const addInfoToast = (message: string) => {
        const key = toastState.add({message, level: 'info'});
        setTimeout(() => {
            toastState.close(key);
            toastState.remove(key);
        }, 5000);
    }

    const addSuccessToast = (message: string) => {
        const key = toastState.add({message, level: 'success'});
        setTimeout(() => {
            toastState.close(key);
            toastState.remove(key);
        }, 5000);
    }

    return (
        <ToastContext.Provider value={{ addErrorToast, addInfoToast, addSuccessToast  }}>
            {children}
            {toastState.visibleToasts.length > 0 && (
                <ToastRegion {...props} state={toastState as any} />
            )}
        </ToastContext.Provider>
    );
}

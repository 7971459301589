import DynamicSvgRenderer from "../../../hooks/useDynamicSVG/useDynamicSVG2";
import {Badge} from "../Badge/Badge";
import './Icon.css';
import {IconStyle} from "../../../types";
import React from "react";

export function Icon({badgeText, name, style, color='var(--grey-7D-color)', size, iconStyle}: {badgeText?:string, name:string, style?: string, color?:string, size?:number, iconStyle?: IconStyle}) {
    return (
        <span style={{display:'grid',gridTemplateColumns: `${badgeText?'1fr 8px 1fr':'1fr'}`}}>
            <span className={'iconHolder'} style={{width: 'fit-content', minWidth: 0 }}>
                <DynamicSvgRenderer name={name} color={color} size={size} style={iconStyle} />
            </span>
            {badgeText &&
            <span className={'badgeHolder'} style={{display: 'grid', width: 'fit-content', minWidth: 0 }}>
                <Badge text={badgeText} style={style}></Badge>
            </span>
            }
        </span>
    )
}

import {Button, DialogTrigger, Input, Label, Modal, SearchField} from "react-aria-components";
import {Icon} from "../../atoms/adornments/Icon/Icon";
import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {HubButton} from "../../atoms/inputs/Button/Button";
import React, {useContext, useEffect} from "react";
import {Login} from "../../organisms/LogIn/Login";
import {HubModal} from "../Modal/Modal";
import '../Modal/Modal.css';
import {Signup} from "../../organisms/Signup/Signup";
import {useSearchParams} from "react-router-dom";
import {useCognito} from "../../hooks/aws/cognito/useCognito";
import {AuthContext} from "../../providers/Signup/AuthContext";

export function SearchHeader({value, onChange}:
                                 {signedIn?: boolean, value?: string, onChange?: (e: any) => void}) {

    const idInfo = sessionStorage.getItem('IdInfo');

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');

    const [type, setType] = React.useState('teaching');
    const [showLogin, setShowLogin] = React.useState(code?true:false);
    const [showSignup, setShowSignup] = React.useState(false);

    const getPicture = () => {
        console.log('ID INFO:', idInfo);
        if (idInfo) {
            return JSON.parse(idInfo)?.picture;
        }
        return undefined;
    }

    const {isLoggedIn} = useContext(AuthContext);

    useEffect(() => {
        console.log('IS LOGGED IN:', isLoggedIn);
    }, [isLoggedIn]);

    return (
        <div className={'flex gap-input items-center bg-white rounded rounded-[16px] p-2 pl-6'}>
            <SearchField onChange={onChange} value={value} className={'flex gap-input items-center flex-1 h-full'} >
                <Icon name={'search'} iconStyle={'Regular'} color={'var(--grey-7D-color)'}></Icon>
                {/*<Label>Search</Label>*/}
                <Input className={'h-full border-0 outline-0 w-full'} placeholder={'Search'} />
            </SearchField>
            {!isLoggedIn && <>
                {/*<MJDSelect></MJDSelect>*/}
                <DialogTrigger>
                    <span>
                        <HubButton onClick={() => setShowLogin(!showLogin)} buttonStyle={'ghost'} text={'Log in'}/>
                    </span>
                    <Modal isDismissable isOpen={showLogin} onOpenChange={setShowLogin} >
                        <Login onChooseSignup={() => {
                            setShowSignup(true);
                            setShowLogin(false);
                        }} />
                    </Modal>
                </DialogTrigger>
                <DialogTrigger>
                    <span>
                        <HubButton onClick={() => setShowSignup(!showSignup)} buttonStyle={'primary'} text={'Sign up'}/>
                    </span>
                    <Modal isDismissable isOpen={showSignup} onOpenChange={setShowSignup} >
                        <>
                            {type === 'teaching' && <Signup onChooseLogin={() => {
                                setShowSignup(false);
                                setShowLogin(true);
                            }} type={type} changeType={() => setType('learning')} onClose={() => setShowSignup(false)}/>}
                            {type === 'learning' && <Signup onChooseLogin={() => {
                                setShowSignup(false);
                                setShowLogin(true);
                            }} type={type} changeType={()=>setType('teaching')} onClose={() => setShowSignup(false)} />}
                        </>
                    </Modal>
                </DialogTrigger>
            </>}
            {isLoggedIn && <>
                <Icon name={'calendar-empty'} badgeText={'9+'} style={'secondary'} />
                <Icon name={'chat'} badgeText={'9+'} style={'secondary'} />
                <img src={getPicture()??'https://placehold.co/56x56'} className={'w-[56px] rounded-[16px]'} />
            </>}
        </div>
    )
}

import {FileUploadService} from "../../../atoms/inputs/FileUpload/FileUpload";
import {AuthService} from "./authService";

export class s3UploadService implements FileUploadService {
    constructor(authService?: AuthService) {
        if (!process.env.REACT_APP_API) {
            throw new Error("Environment variable REACT_APP_API is not set.");
        }
        this.auth = authService;
    }

    private readonly auth: AuthService | undefined;

    async uploadOne(file: File, chunked: boolean = true) {
        console.log('uploading one file');
        if (chunked) {
            this.uploadChunked(file)
        }
        else {
            throw new Error('Method not implemented.');
        }
    }

    async uploadMany(files: File[], uploadChunked: boolean = true) {
        console.log('uploading many files');
        const uploadPromises = files.map(file => this.uploadOne(file, uploadChunked));
        const results = await Promise.allSettled(uploadPromises);
        const successes = results.filter(result => result.status === 'fulfilled');
        const failures = results.filter(result => result.status === 'rejected');
        return { successes, failures };
    }

    async uploadChunked(file: File) {
        throw new Error('Method not implemented.');
    }
}

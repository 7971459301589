import {HubButton} from "../../atoms/inputs/Button/Button";
import {Menu, MenuItem} from "react-aria-components";
import React from "react";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import Img from '../../assets/images/logo.svg';

export function Footer() {
    return (
        <div className={'flex justify-between px-6 pt-6'}>
            <div className={'flex flex-col gap-8 items-start'}>
                <img src={Img} alt="" className={'h-8'}/>
                <div>
                    © 2023 Language hub. All rights reserved Terms of Use | Privacy Policy
                </div>
                <div className={'flex gap-8'}>
                    <DynamicSvgRenderer name={'Facebook'} style={'Logos'} color={'black'} />
                    <DynamicSvgRenderer name={'Twitter'} style={'Logos'} color={'black'} />
                    <DynamicSvgRenderer name={'Instagram'} style={'Logos'} color={'black'} />
                    <DynamicSvgRenderer name={'Google'} style={'Logos'} color={'black'} />
                    <DynamicSvgRenderer name={'Apple'} style={'Logos'} color={'black'} />
                </div>
            </div>
            <div className={'flex gap-12'}>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>About us</h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Who we are</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>How we work</MenuItem>
                    </Menu>
                </div>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>For students</h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Individual lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Group lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Find your perfect
                            tutor</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Learning Code of
                            Conduct</MenuItem>
                    </Menu>
                </div>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>Online language tutors </h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>English tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Spanish tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>French tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Italian tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>German tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Arabic tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Russian tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Korean tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Chinese tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Japanese tutors</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Find more
                            language tutors</MenuItem>
                    </Menu>
                </div>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>Online language lessons </h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>English lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Spanish lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>French lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Italian lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>German lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Arabic lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Russian lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Korean lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Chinese lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Japanese lessons</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Browse
                            language lessons</MenuItem>
                    </Menu>
                </div>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>For tutors</h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Become a tutor</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Teaching Code of
                            Conduct</MenuItem>
                    </Menu>
                </div>
                <div className={'flex gap-4 flex-col'}>
                    <h3 className={'text-title-18 text-grey-41'}>Help</h3>
                    <Menu className={'flex flex-col gap-input'}>
                        <MenuItem className={'text-body-14 text-grey-7D'}>Support</MenuItem>
                        <MenuItem className={'text-body-14 text-grey-7D'}>FAQs</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>

    )
}

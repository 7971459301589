import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Fieldset} from "../../layout/Fieldset/Fieldset";
import {useFormContext} from "react-hook-form";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import ToggleButtons from "../../atoms/inputs/ToggleButtons/ToggleButtons";
import {UserSignupContext} from "../../providers/Signup/UserSignupContext";
import {HookTextField} from "../../atoms/inputs/TextField/HookTextField";
import {HookSelectField} from "../../atoms/inputs/Select/HookSelectField";
import {Shimmer} from "../../atoms/Shimmer/Shimmer";

const languages = [
    {
        name: 'English',
        flagName: 'British',
    },
    {
        name: 'German',
        flagName: 'German',
    },
    {
        name: 'French',
        flagName: 'French',
    },
    {
        name: 'Spanish',
        flagName: 'Spanish',
    },
    {
        name: 'Japanese',
        flagName: 'Japanese',
    },
    {
        name: 'Greek',
        flagName: 'Greek',
    },
    {
        name: 'Italian',
        flagName: 'Italian',
    },
    {
        name: 'Korean',
        flagName: 'South-Korean',
    },
    {
        name: 'Ukranian',
        flagName: 'Ukranian',
    },
];

export function LanguageSelectionPage({index}:{index?:number}) {

    const {reset, setError, watch, getValues, handleSubmit, control, setValue, clearErrors, formState: { errors } } = useFormContext();

    const spokenLanguages = watch("spokenLanguages.map(language => language.level)");

    useEffect(() => {
         console.log('SPOKEN LANGUAGES CHANGE', getValues('spokenLanguages'));
        clearErrors('spokenLanguages');
    }, [spokenLanguages, setError, clearErrors]);

    useEffect(() => {
        console.log('ERRORS:', errors);
    }, [errors]);

    const [selectedLanguages, setSelectedLanguages] = useState([] as string[]);
    const [selected, setSelected] = useState([] as string[]);

    const {user} = useContext(UserSignupContext);

    useEffect(() => {
        //console.log('spoken LANGUAGES:', user);
        //setSelectedLanguages(user?.spokenLanguages?.map((element: any) => element.name)??[]);
        setSelected(user?.spokenLanguages?.map((element: any) => element.name)??[]);
        reset({ spokenLanguages: user?.spokenLanguages });
    }, [user]);

    /*useEffect(() => {
        console.log('selected', selected);
    }, [selected]);*/

    /*type LanguageLevel = { level: string };
    const languageLevel = watch('spokenLanguages');
    const levelFields = languageLevel.map((_:any, index:number) => `spokenLanguages[${index}].level`);
    const languageLevels = watch(levelFields);
    const getDropdownItems = (currentIndex:number) => {
        const nativeLanguageSet = languageLevels?.some((element: LanguageLevel) => element.level === 'Native');
        const nativeIs = languageLevels?.filter((element: LanguageLevel) => element.level === 'Native');
        console.log('LANGUAGE LEVELS:', languageLevels, "INDEX:", currentIndex, 'NATIVE LANGUAGE SET', nativeIs);
        return (nativeLanguageSet && (languageLevels as LanguageLevel[])[currentIndex].level !== 'Native') ?
            ['Beginner', 'Intermediate', 'Advanced', 'Expert'] :
            ['Native', 'Beginner', 'Intermediate', 'Advanced', 'Expert']??[];
    };*/

    /*useEffect(() => {
        console.log('LANGUAGE LEVELS useEffect:', languageLevels);
    }, [languageLevels]);*/

    return (
        <>
            <section>
                <div className={'flex flex-col bg-white py-8 px-6 rounded gap-6'}>
                    <h2>The languages you speak:</h2>
                    <div className={'grid grid-cols-3 gap-2 '}>
                        <ToggleButtons defaultValue={selected}
                                       onChange={setSelected}
                                       itemOptions={languages.map((item:any) => item.name)}
                                       renderButton={(item, isSelected, toggleItem, index) => (
                                           <Shimmer isLoading={user ? false : true}>
                                               <button type={'button'} onClick={() => toggleItem(item)}
                                                       className={`w-full py-3 flex items-center justify-center rounded hover:bg-primary-hover cursor-pointer border-solid border-[1px] hover:border-primary ${isSelected ? 'bg-primary-hover border-primary' : 'bg-bg border-white'}`}>
                                                   <label className={'flex gap-2'}>
                                                       <DynamicSvgRenderer name={languages[index].flagName}
                                                                           style={'Flags'}/>
                                                       {item}
                                                   </label>
                                               </button>
                                           </Shimmer>
                                       )}/>
                    </div>
                </div>
                {selected.length > 0 && <>
                    <hr/>
                    <h2>Language level:</h2>
                    <span className={`${errors?.spokenLanguages?.message && 'text-error'}`}>You must set exactly one language to Native level.</span>
                    {selected.map((item, index) => {

                            return (
                                <Fieldset key={index}>
                            <span className={'col-span-6'}>{<HookTextField control={control}
                                                                           placeholder={'Language'}
                                                                           labelText={index === 0 ? 'Language' : ''}
                                                                          name={`spokenLanguages[${index}].name`}
                                                                          defaultValue={selected[index]}
                                                                           isReadOnly={true}
                            />}</span>
                                            <span className={'col-span-6 flex'}>
                                        <HookSelectField placeholderText={'Select your level'}
                                                         name={`spokenLanguages[${index}].level`}
                                                         control={control}
                                                         items={['Native', 'Beginner', 'Intermediate', 'Advanced', 'Expert']}

                                        /></span>
                                        </Fieldset>
                                    )
                                }
                            )}
                        </>}
            </section>
        </>
)
}

import {Button, Dialog, Link, PressEvent} from "react-aria-components";
import Img from '../../assets/images/login.svg';
import {HubButton} from "../../atoms/inputs/Button/Button";
import {Icon} from "../../atoms/adornments/Icon/Icon";
import React, {useContext, useEffect} from "react";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {AuthContext} from "../../providers/Signup/AuthContext";
import {HookTextField} from "../../atoms/inputs/TextField/HookTextField";
import {useApi} from "../../hooks/useApi/useApi";

export function Login({onChooseSignup} : {onChooseSignup?: () => void}) {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const navigate = useNavigate();
    const cognito = useContext(AuthContext);
    const {callProtectedApi} = useApi();

    const onLogin = async (e: PressEvent) => {
        console.log('LOGIN BUTTON pressed');
        console.log('LOGIN code is:', code);
        cognito.setCode(code??'');
        if (code && cognito.confirmSignup) {
            const confirmed = await cognito.confirmSignup(e);
            console.log('LOGIN CODE CONFIRMED');
        }
        console.log('LOGIN CODE EXISTS', cognito.login);
        if (cognito.login) {
            try {
                console.log('ATTEMPTING LOGIN');
                const login: any = await cognito.login(e);
                console.log('LOGIN RESPONSE:', login);
                const {idInfo, accessToken} = login;
                console.log('LOGIN RESPONSE PARTS:', {idInfo, accessToken});
                const verificationResponse = await callProtectedApi({method: 'GET', resourceType:'cognito', resourceName:'verify',
                                                                requestHeaders: {
                                                                    'Content-Type': 'application/json',
                                                                    'Authorization': accessToken
                                                                }})

                const mode = idInfo['custom:registration_type'];
                console.log('LOGIN Mode:', mode);

                cognito.valueUpdated && cognito.valueUpdated(idInfo);

                if (mode === 'teaching') {
                    navigate('/signup/teach-select');
                } else {
                    navigate('/signup/profile');
                }

                //handleSignupType(type);
                //navigate('/language-select');
            }
            catch (e: any) {

            }
        }
    }

    return (
        <Dialog>
            {({ close }) => (
                <div className={'flex gap-3 bg-white w-full rounded-lg'}>
                    <img src={Img} alt="" className={'w-[360px]'}/>
                    <section className={'p-6 flex flex-col w-[400px] gap-6 relative'}>
                        <div className={'flex flex-col gap-3 text-body-14'}>
                            <h2 className={'text-title-24'}>Login</h2>
                            <p>By signing up or logging in, you agree to our
                                Terms of Service and Privacy Policy.</p>
                        </div>
                        <form>
                            <fieldset className={'flex flex-col gap-3'}>
                            <HookTextField validationRules={{ required: `Email is required.`,  pattern: {
                                    value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                    message: "Invalid email address"
                                } }} {...cognito.useField('email')} placeholder={'E-mail'} Icon={<Icon name={'mail'}/>} />
                           <HookTextField {...cognito.passwordField}  description={'Password requirements'} Icon={<Icon name={'key-square'}/>} />
                            </fieldset>
                            <div className={'flex'}>
                                <HubButton
                                    text={`Login`}
                                    onClick={(e) => onLogin(e)}
                                />
                            </div>
                            {!code && <>{/*<div className={'flex flex-col gap-input'}>
                            <span className={'text-center'}>or continue with</span>
                            <span className={'flex justify-between gap-[17px]'}>
                                <HubButton style={'secondary'} leftIcon={'Google'} leftIconStyle={'Logos'} />
                            </span>
                        </div>*/}
                                <span className={'flex h-10 justify-between absolute bottom-[20px] w-[350px]'}>
                             <Button onPress={onChooseSignup} className={'cursor-pointer'}>Sign up</Button>
                            <Link className={'cursor-pointer'}>Forgot password?</Link>
                        </span></>}
                        </form>
                    </section>
                </div>
            )}
        </Dialog>
    )
}

import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {useApi} from "../../hooks/useApi/useApi";

// Create a context
export const UserSignupContext = createContext({ isLoading: true, user: undefined as any, refreshUser: (()=>{}) as (() => void)
});

// Create a provider component
export const UserSignupProvider = ({ children }: {children: ReactNode}) => {
    const [user, setUser] = useState<any>(undefined);
    const [isLoading, setIsLoading] = React.useState(true);
    const {callProtectedApi} = useApi();

    useEffect(() => {
        user && setIsLoading(false);
    }, [user]);

    const refreshUser = async () => {
        const response = await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'GET'});
        console.log('response', response);
        const tutor = response?.Items?response.Items[0]:undefined;
        setUser(tutor);
    }

    useEffect(() => {
        refreshUser();
    }, []);

    return (
        <UserSignupContext.Provider value={{ user, refreshUser, isLoading }}>
            {children}
        </UserSignupContext.Provider>
    );
};

import {HubCheckbox} from "../../atoms/inputs/Checkbox/Checkbox";
import {Icon} from "../../atoms/adornments/Icon/Icon";
import {MJDSelect} from "../../atoms/inputs/Select/MJDSelect";
import {HubButton} from "../../atoms/inputs/Button/Button";
import React, {useEffect, useMemo, useState} from "react";
import {number} from "prop-types";

type TimeSpan = {
    start: string | undefined;
    end: string | undefined;
}


export function TimePairs({ index, slots, setSlots } : {index: number, slots?: any, setSlots?: any, endSlots?: any}) {
    // useMemo to calculate sorted slots and boundary only when `slots` changes.
    const { sortedSlots, previous, next } = useMemo(() => {
        const sorted = sortTimeSlots(slots);
        const sortedIndex = sorted.findIndex((slot: TimeSpan) => {
            console.log('slot.start: ', slot.start, 'slots[index]?.start:', slots[index]?.start, 'slot.end: ', slot.end,
                'slots[index]?.end:', slots[index]?.end, 'index:', index, 'slots:', slots, 'sorted:', sorted);
            return slot.start == slots[index]?.start && slot.end == slots[index]?.end
        });
        console.log('sortedIndex: ', sortedIndex);
        const returnVal = {
            sortedSlots: sorted,
            previous: sorted[index]?.end && sortedIndex > 0 ? sorted[sortedIndex - 1] : null,
            next: sorted[index]?.start && sortedIndex < sorted.length - 1 ? sorted[sortedIndex + 1] : null
        };
        console.log('previous: ', returnVal.previous, '; next: ', returnVal.next);
        return returnVal;
    }, [slots, index]);  // Include index in the dependency array

    // Simplified time range generator
    const generateTimeRange = (startTime:string, endTime:string, excludeSlots: TimeSpan[]) => {
        let times = [];
        const startMinutes = convertTimeToMinutes(startTime);
        const endMinutes = convertTimeToMinutes(endTime);
        //getAllHalfHoursInDay.filter(time => {
        //             return (convertTimeToMinutes(time) > startTimeInMinutes) &&
        //                 (!timeInExistingSlots(convertTimeToMinutes(time)))
        for (let currentTime = startMinutes; currentTime < endMinutes; currentTime += 30) {
            let hours = Math.floor(currentTime / 60);
            let minutes = currentTime % 60 === 30 ? 30 : 0; // Ensure alignment to half-hour slots
            const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            if (!isTimeInSlots(timeString, excludeSlots.filter((_, idx) => idx !== index))) {
                times.push(timeString);
            }
        }
        return times;
    };

    const processChange = (value: any, key: string) => {
        console.log('slots: ', JSON.stringify(slots));
        const updatedSlots = slots.map((slot: TimeSpan, idx: number) => idx === index ? { ...slot, [key]: value } : slot);
        console.log('updatedSlots: ', JSON.stringify(updatedSlots));
        setSlots(updatedSlots);
    };

    const isTimeInSlots = (time: string, slots: TimeSpan[]) => {
        const timeInMinutes = convertTimeToMinutes(time);
        return slots.some(slot => {
            if (!slot.start || !slot.end) return false;
            const start = convertTimeToMinutes(slot.start);
            const end = convertTimeToMinutes(slot.end);
            return timeInMinutes >= start && timeInMinutes < end;
        });
    };

    return (
        <div key={index} className="flex gap-4 items-center">
            <MJDSelect value={slots[index]?.start || ''}
                       items={generateTimeRange(previous?.end || "00:00", slots[index]?.end || "24:00", sortedSlots)}
                       onChange={(value:any) => processChange(value, 'start')}/>
            <span className="mb-6">to</span>
            <MJDSelect value={slots[index]?.end || ''}
                       items={generateTimeRange(slots[index]?.start || "00:00", next?.start || "24:00", sortedSlots)}
                       onChange={(value:any) => processChange(value, 'end')}/>
            <button className="mb-6" onClick={() => setSlots(slots.filter((_: any, idx: number) => idx !== index))}>
                <Icon name="trash"/>
            </button>
        </div>
    );
}

function sortTimeSlots(timeSlots: TimeSpan[]) {
    return timeSlots.sort((a, b) => {
        if (!a.start || !b.start) return 0;
        return convertTimeToMinutes(a.start) - convertTimeToMinutes(b.start)
    });
}

function convertTimeToMinutes(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
}

import {Configure, Hits, InstantSearch, RangeInput, RefinementList, useRefinementList} from "react-instantsearch";
import {SearchHeader} from "../../molecules/SearchHeader/SearchHeader";
import {FacetDropdown} from "../../FacetDropdown";
import {ResultsCount} from "../../ResultsCount";
import {IndividualCard} from "../../organisms/Cards/Individual/IndividualCard";
import React from "react";
import algoliasearch from "algoliasearch/lite";
import {Sidebar} from "../../organisms/Sidebar/Sidebar";
import Img from "../../assets/images/logo.svg";
import {Footer} from "../../organisms/Footer/Footer";


export function Search() {
    const searchClient = algoliasearch('IEAQGRIE37', 'f354e93ed25f18acf675d9c6b80901bc', {

    });
    const [query, setQuery]: any = React.useState('');
    const [numJobsToDisplay, setNumJobsToDisplay] = React.useState(24);



    return (
        <>
            <div>
                <div className={'gap-8 flex'}>
                    <div className={'bg-white w-[232px] pt-8 rounded rounded-[16px] my-4'}>
                        <div className={'bg-white w-[232px] sticky top-8 h-[100vh] flex flex-col gap-8'}>
                            <img src={Img}
                                 className={'rounded-2xl w-[108px] flex-0 ml-4'}/>
                            <Sidebar/>
                        </div>
                    </div>
                    <InstantSearch searchClient={searchClient} indexName="language-hub">

                        <Configure query={query} hitsPerPage={numJobsToDisplay}/>
                        <div className={'flex flex-col gap-8 w-full'}>
                            <div className={'gap-8 flex flex-col sticky top-[0] p-4 pb-[0] z-20 bg-bg'}>
                                <SearchHeader value={query} onChange={setQuery}/>
                                <div className={'flex flex-col gap-8'}>
                                    <h1>Find a tutor</h1>
                                    <span>Customise your tutor search using the filters below to find your perfect tutor:</span>
                                    <div className={'flex gap-4'}>
                                        <FacetDropdown closeOnChange={true} buttonText={'Language'}>
                                            <RefinementList operator="and" attribute="language"/>
                                        </FacetDropdown>
                                        <FacetDropdown closeOnChange={true} buttonText={'Also speaks'}>
                                            <RefinementList operator="and" attribute="language2"/>
                                        </FacetDropdown>
                                        <FacetDropdown closeOnChange={true} buttonText={'Price range'} isRange={true}>
                                            <RangeInput attribute={'price'}/>
                                        </FacetDropdown>
                                        <FacetDropdown closeOnChange={true} buttonText={'Availability'}>
                                            <RefinementList operator="and" attribute="employer.location.type"/>
                                        </FacetDropdown>
                                        <FacetDropdown closeOnChange={true} buttonText={'Country of birth'}>
                                            <RefinementList operator="and" attribute="country"/>
                                        </FacetDropdown>
                                        {/*<button
                                            className={'rounded-[8px] border-solid border-black border-[1px] h-[48px] w-[60px]'}
                                            onClick={() => setAudience("student")}>...
                                        </button>*/}
                                    </div>
                                    <span><ResultsCount/></span>
                                </div>
                            </div>
                            <div className={'flex flex-col gap-[50px] p-4 pt-[0]'}>
                                <Hits hitComponent={IndividualCard}/>
                            </div>
                        </div>
                    </InstantSearch>
                </div>
            </div>
            <Footer ></Footer>
        </>

    )
}

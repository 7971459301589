import {Breadcrumb, Breadcrumbs, Link} from "react-aria-components";
import './Breadcrumb.css';
import React, {useContext, useEffect, useMemo} from "react";
import {SignupContext} from "../../providers/Signup/SignupContext";

export function HubBreadcrumb({links=[{name:'Home', url:'/'}, {name:'React Aria', url:'/react-aria', current:true}, {name:'Breadcrumbs', url:'/'}]}:{links?:{name:string, url:string, current?:boolean, completed?:boolean}[]}) {
    const {currentStage, setCurrentStage, stages
} = useContext(SignupContext);

    useEffect(() => {
        console.log('STAGES:', stages);
    }, [stages]);

    const firstNonCompleted = useMemo(() => {
        const first =  stages.findIndex((stage) => !stage.completed);
        console.log('FIRST:', first);
        return first;
    }, [stages]);

    useEffect(() => {
        console.log('CURRENT:', currentStage);
    }, [currentStage]);

    return (
        <Breadcrumbs>
            {stages.map((link, index) => <Breadcrumb  key={index}><Link isDisabled={!link.completed && index>0 && index > firstNonCompleted} className={` ${!link.completed && index>0 && index > firstNonCompleted ? 'cursor-auto' : 'cursor-pointer'} ${!link.completed && index>0 && index > firstNonCompleted?'font-light':'font-semibold'} ${currentStage===index&&'current font-bold'} `} href={'/signup'+link.url}>{link.name}</Link></Breadcrumb>)}
        </Breadcrumbs>
    )
}

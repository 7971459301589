import React from "react";

export function Badge({text = '9+', style='primary'}: {text?: string, style?: string}) {
    let colorClasses = style === 'primary'? 'bg-white text-primary' :  'bg-primary text-white';

    return (
        <span className={`border-2 rounded-full h-6 text-badge font-bold flex items-center justify-center p-[2px] 
        ${style==='primary'?'border-primary text-primary bg-white':''}
        ${style==='secondary'?'border-secondary text-white bg-primary':''}
        ${style==='tertiary'?'border-tertiary text-white bg-primary':''}
        ${style==='ghost'?'border-tertiary text-white bg-primary':''}
        `}>
            {text}
        </span>
    )
}

import React, {useContext} from "react";
import {HubButton} from "../../atoms/inputs/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import DynamicSvgRenderer from "../../hooks/useDynamicSVG/useDynamicSVG2";
import {SignupContext} from "../../providers/Signup/SignupContext";
import {useFormContext} from "react-hook-form";
import {ToastContext} from "../Toast/ToastProvider";

export function SignupNav() {
    const {clearErrors, formState: {errors}} = useFormContext()??{formState:{}};

    const navigate = useNavigate();
    const signupContext = useContext(SignupContext);
    const stages = signupContext.stages;
    const {addSuccessToast, addErrorToast} = useContext(ToastContext);

    const goBack = () => {
        signupContext.setCurrentStage(signupContext.currentStage-1);
        navigate('/signup' + stages[signupContext.currentStage-1].url);
    }

    const handleValidation = () => {
        clearErrors('spokenLanguages');
        if (errors) {
            Object.keys(errors).forEach((key) => {
                addErrorToast && addErrorToast(errors[key]?.message?.toString()??'');
            });
        }
    }

    return (
        <div className={'flex gap-input items-center bg-white p-6 pl-6 justify-center fixed w-full bottom-[0]'}>
            <div className={'flex  gap-input w-full justify-end mx-auto max-w-[871px]'}>
                {signupContext.currentStage > 0 && <span>
                        <HubButton type={'button'} buttonStyle={'secondary'} text={'Previous step'} onClick={goBack} />
                </span>}
                {signupContext.currentStage < stages.length - 1 && <span>
                        <HubButton onClick={handleValidation} disabled={signupContext.submitDisabled} type={'submit'} buttonStyle={'primary'} text={'Next step'} />
                </span>}
                {signupContext.currentStage >= stages.length - 1 && <span className={'h-10'}>
                </span>}
                <button className={'absolute right-8 bg-black text-white h-10 px-4 rounded-[32px] flex gap-2 items-center'}>
                    <DynamicSvgRenderer color={'white'} name={'message-question'} />
                    Need help?
                </button>
            </div>
        </div>
    )
}

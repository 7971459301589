import {indexOfMonth, twoDigits} from "../../utils";
import {useApi} from "../../hooks/useApi/useApi";
import {AuthContext} from "./AuthContext";
import {useContext} from "react";



export const usePages = () => {
    const cognito = useContext(AuthContext);
    const {callProtectedApi} = useApi();

    const teachSelect = {
        name: "Teach language",
        url: "/teach-select",
        validate: (data: any) => {
            console.log('VALIDATING:', data);
            return {
                value: (data.taughtLanguages as any[])?.length > 0,
                message: 'You must select at least one language to teach.',
                key: 'taughtLanguages'
            };
        },
        submit: async (data: any) => {
            console.log('DATA SUBMIT:', data);
            const {taughtLanguages} = data;
            sessionStorage.setItem('taughtLanguages', JSON.stringify(taughtLanguages));
            const updateData = {taughtLanguages};
            await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({updateData})});
        }
    };

    const speakSelect = {
        name:'Speak language',
        url:'/language-select',
        validate: (data: any) => {
            console.log('VALIDATING:', data);
            return {
                value: (data.spokenLanguages as any[]).some((element) => element.level === 'Native'),
                message: 'You must select at least one language as Native.',
                key: 'spokenLanguages'
            };
        },
        submit: async (data: any) => {
            console.log('DATA SUBMIT:', data);
            const {spokenLanguages} = data;
            sessionStorage.setItem('taughtLanguages', JSON.stringify(spokenLanguages));
            const updateData = {spokenLanguages};
            await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({updateData})});
        }
    };

    const yourSchedule = {
        name:'Your Schedule',
        url:'/lesson-schedule',
        validate: (data: any) => {
            console.log('DATA SUBMIT:', data);
            return {
                value: true
            };
        },
        submit: async (data: any) => {
            console.log('DATA:', data);
            const {timetable, timezone} = data;
            sessionStorage.setItem('timetable', JSON.stringify(timetable));
            const updateData = {timetable, userTimeZone: timezone};
            await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({updateData})});
        }
    };

    const profile = {
        name:'Profile',
        url:'/profile',
        validate: (data: any) => {
            return {
                value: true
            };
        },
        submit: async (data: any) => {
            console.log('DATA :', data);
            let { cognitoAtts, ID, ...dbData } = data;
            const { name, family_name } = cognitoAtts;
            let { birthdate, ...cognitoAttsRest } = cognitoAtts;
            const birthdateDateJoined = `${birthdate.year}-${birthdate.month}-${birthdate.day}`;
            cognitoAttsRest.birthdate = birthdateDateJoined;
            console.log('COGNITO ATTS:', cognitoAttsRest);
            console.log('DB DATA:', dbData);
            //await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({...dbData, name, family_name})});
            //await callProtectedApi({resourceType:'cognito-attributes', resourceName:'', method:'POST', body:JSON.stringify(cognitoAttsRest)});
        }
    };

    const dateAsCognitoBirthdate = (date: number) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }

    const teacherprofile = {
        ...profile,
        submit: async (data: any) => {
            console.log('DATA :', data);
            let { cognitoAtts, ID, ...updateData } = data;
            const oldAtts = JSON.parse(sessionStorage.getItem('IdInfo') as string);
            const combinedAtts = {...oldAtts, ...cognitoAtts};
            const { name, family_name, birthdate, phone_number, picture } = cognitoAtts;
            const birthdateDateJoined = `${birthdate.year}-${twoDigits(indexOfMonth(birthdate.month)+1)}-${birthdate.day}`;
            combinedAtts.birthdate = birthdateDateJoined;
            cognito.valueUpdated && cognito.valueUpdated(combinedAtts);
            sessionStorage.setItem('IdInfo', JSON.stringify(combinedAtts));
            updateData.firstname = name;
            updateData.family_name = family_name;
            console.log('DB DATA:', updateData);
            await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({updateData})});
            await callProtectedApi({
                resourceType:'cognito-attributes', resourceName:'', method:'POST',
                body:JSON.stringify({name, birthdate: birthdateDateJoined, family_name, phone_number, picture})
            });
        }
    };

    const studentprofile = {
        ...profile,
        submit: async (data: any) => {
            let { cognitoAtts, ID, ...updateData } = data;
            const oldAtts = JSON.parse(sessionStorage.getItem('IdInfo') as string);
            const combinedAtts = {...oldAtts, ...cognitoAtts};
            const { name, family_name, birthdate, phone_number, picture } = cognitoAtts;
            const birthdateDateJoined = `${birthdate.year}-${twoDigits(indexOfMonth(birthdate.month)+1)}-${birthdate.day}`;
            combinedAtts.birthdate = birthdateDateJoined;
            cognito.valueUpdated && cognito.valueUpdated(combinedAtts);
            sessionStorage.setItem('IdInfo', JSON.stringify(combinedAtts));
            await callProtectedApi({
                resourceType:'cognito-attributes', resourceName:'', method:'POST',
                body:JSON.stringify({name, birthdate: birthdateDateJoined, family_name, phone_number, picture})
            });
        }
    };

    const verification = {
        name:'Verification',
        url:'/verification',
        validate: (data: any) => {
            return {
                value: true
            };
        },
        submit: async (data: any) => {
            console.log('DATA:', data);
            const {verification} = data;
            sessionStorage.setItem('timetable', JSON.stringify(verification));
            const updateData = {verification};
            await callProtectedApi({resourceType:'dynamo', resourceName:'tutors', method:'PUT', body:JSON.stringify({updateData})});
        }
    };

    const tutorSuccess = {
        name:'Complete!',
        url:'/success',
        validate: (data: any) => {
            return {
                value: true
            };
        },
        submit: (data: any) => {
            console.log('DATA:', data);
        }
    };

    const preferences = {
        name:'Preferences!',
        url:'/preferences',
        validate: (data: any) => {
            return {
                value: true
            };
        },
        submit: async (data: any) => {
            console.log('DATA:', data);
            const {timezone, language, currency} = data;
            await callProtectedApi({
                resourceType:'cognito-attributes', resourceName:'', method:'POST',
                body:JSON.stringify({"custom:timezone":timezone, "custom:language": language, "custom:currency": currency})
            });
        }
    };

    const search = {
        name:'Search!',
        url:'/search',
        validate: (data: any) => {
            return {
                value: true
            };
        },
        submit: (data: any) => {
            console.log('DATA:', data);
        }
    }

    return {teachSelect, speakSelect, yourSchedule, teacherprofile, studentprofile, verification, tutorSuccess, preferences, search};

}


import {usePages} from "./usePages";

export type Stage = {
    name: string,
    url: string,
    current?: true
    validate?: (data: any) => { value: boolean, message?:string, key?:string }
    submit?: (data: any) => void,
    completed?: boolean
}

export const useSignupStages = () => {
    const {
        studentprofile,
        teacherprofile,
        speakSelect,
        teachSelect,
        tutorSuccess,
        verification,
        yourSchedule,
        preferences,
        search
    } = usePages();

    const teachingStages: Stage[] = [teachSelect, speakSelect, yourSchedule, teacherprofile, verification, tutorSuccess];

    const learningStages: Stage[] = [studentprofile, preferences, search];

    return {teachingStages, learningStages};
}
